/* eslint-disable no-param-reassign */
/* eslint-disable max-len */
/* eslint-disable array-callback-return */
import React, { useState, useEffect } from "react"
import styled from "styled-components"
import _, { isEmpty, parseInt, get } from "lodash"
import root from "window-or-global"
import {
  Select, Button, Input, Switch, Modal, Spin,
} from "antd"
import Loader from "../../../common/UI/Loader"
import PlusSign from "./PlusOutlined.svg"
import container from "../../../../container/BrokerDashboard/editAgentContainer"

const Wrap = styled.div`
  margin: 20px;
`
const Title = styled.h1`
  margin-top: 34px;
  margin-left: 17px;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  ${props => props.body && `
  margin-top: 0px;
  padding-left: 46px;
`}
`
const Content = styled.div`
  margin-top: 58px;
  margin-left: 17px;
  width: 1040px;
  height: 100%;
  .Content::-webkit-scrollbar {
    display: none;
  };
`
const HeaderWrap = styled.div`
  padding-left: 46px;
  padding-top: 48px;
  padding-right: 28px;
  display: flex;
  justify-content: space-between;
`
const TitleWrap = styled.div`
  padding-left: 46px;
  padding-top: 48px;
  padding-right: 28px;
  display: flex;
  justify-content: flex-start;
`

const AddNewDoc = styled.div`
  display: flex;
  margin-left: 48px;
  margin-top: 18px;
  cursor: pointer;
  ${props => props.colist && `
  margin-left: 0px;
`}

`
const Image = styled.img`
  margin-right: 16px;
`
const AddNew = styled.h3`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  text-align: center;
  color: #100063;
  margin: 0px;
`
const DropdownDiv = styled.div`
  display: flex;
`
const Subtitle = styled.h2`
  margin: 0px;
  margin-top: 6px;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  ${props => props.body && `
  margin-top: 0px;
  padding-left: 63px;
  font-size: 16px;
`}
`
const HR = styled.div`
  width: 100%;
  height: 0px;
  border: 1px solid #E6E6E6;
  margin-top: 32px;
  margin-left: 40px;
  ${props => props.colist && `
  margin-left: -9px;
  `}
  ${props => props.last && `
  margin-left: -9px;
  margin-bottom: 30px;
  `}
`
const Body = styled.div`
  // scroll: scroll;
  // overflow-x: hidden;
  height: 1000px;
  &::-webkit-scrollbar {
    display: none;
  }
`
const TitleDiv = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 32px;
`
const InfoDiv = styled.div`
  width: 990px;
  height: 102px;
  background: #FFFFFF;
  border: 1px solid #DDDDDD;
  margin-top: 87px;
  margin-left: 50px;
  display: flex;
  justify-content: space-around;
  ${props => props.body && `
  margin-top: 23px;
  margin-bottom: 44px;
`}
${props => props.stuck && `
  margin-top: 0px;
  margin-bottom: 44px;
  margin-left: 0px;
`}
${props => props.first && `
  margin-top: 0px;
  margin-bottom: 44px;
`}
${props => props.owner && `
  padding-left: 0px;
  margin-left: 0px;
  margin-top: 23px;
`}
`
const Section = styled.div`
  display: flex;
  flex-direction: column;
`
const SmallTitle = styled.h3`
  font-size: 14px;
  font-weight: 200;
  margin-top: 8px;
`
const AdditionDocuments = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 86px;
`
const Divider = styled.div`
  display: flex;
  align-items: baseline;
  margin-bottom: 35px;
`
const SmallHR = styled.div`
  width: 400.5px;
  height: 0px;
  border: 1px solid #E6E6E6;
  margin-top: 32px;
  margin-left: 40px;
`
const SmallText = styled.h3`
  width: 158px;
  margin-left: 16px;
  margin-right: 16px;
  margin-bottom: 0px;
  margin-top: 0px;
  padding: 0px;
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.85);
`
const MLSDiv = styled.div`
  display: flex;
  justify-content: space-between;
  width: 250px;
  ${props => props.body && `
  padding-left: 0px;
  `}
  ${props => props.contingency && `
    padding-left: 0px;
    width: 800px;
  `}
`
const Text = styled.p`
  margin: 0px;
  width: 553px;
  padding-left: 63px;
  margin-top: 20px;
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;
  ${props => props.colist && `
  font-weight: 100;
  color: #8A8A8A;
  padding-left: 0;
  `}
  ${props => props.key && `
  font-weight: 100;
  padding-left: 0;
  `}
`
const CoListingDiv = styled.div`
  margin-top: 41px;
  padding-left: 46px;
`
const OwnershipDiv = styled.div`
  margin-top: 41px;
  padding-left: 46px;
`
const KeysafeDiv = styled.div`
  padding-left: 50px;
  margin-top: 35px;
`
const InvalidText = styled.h3`
  margin-top: 5px;
  margin-bottom: 0px;
  color: red;
  font-size: 12px;
`

const TitleAndTemplate = ({
  isEdit,
  valueName,
  valueTemplate,
  entityName,
  entityType,
  addArray,
  setAddArray,
  templateList,
  index,
  state,
  isShifted,
  templateData,
  setTemplateInvalid,
  deleteTemplates,
  localTemplateData,
  setLocalTemplateData,
  setTemplateList,
}) => {
  const [name, setName] = useState("")
  const [template, setTemplate] = useState("")
  const [isInvalidMessage, setIsInvalidMessage] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  const [invalidArray, setInvalidArray] = useState([])
  console.log({ templateList, index, localTemplateData })

  useEffect(() => {
    setName(valueName)
  }, [valueName])

  useEffect(() => {
    setTemplate(valueTemplate)
  }, [valueTemplate])

  const handleAddTemplate = (nameTarget, templateTarget) => {
    if (!isEmpty(templateList)) {
      templateList.map((x) => {
        if (x.idToAdd === index) {
          if (index.includes("old-")) {
            const id = parseInt((index.split("-").pop()), 10)
            x.name = nameTarget || ""
            x.id = id
            x.idToAdd = index
            x.templateId = templateTarget || ""
            x.entityName = entityName
            x.entityType = entityType
            x.state = state
            x.isEdited = true
          } else if (entityType === "mls_setup") {
            x.name = nameTarget || ""
            x.idToAdd = index
            x.templateId = templateTarget || ""
            x.entityType = entityType
            x.state = state
          } else {
            x.name = nameTarget || ""
            x.idToAdd = index
            x.templateId = templateTarget || ""
            x.entityName = entityName
            x.entityType = entityType
            x.state = state
          }
        }
      })
    }
  }

  const handleDelete = (indexLocal) => {
    const checkId = indexLocal.split("-").pop()
    if (indexLocal.includes("old-")) {
      templateData.map((x) => {
        if (x.id.toString() === checkId) {
          deleteTemplates.push(x.id)
          localTemplateData.splice(localTemplateData.indexOf(x), 1)
          setLocalTemplateData([...localTemplateData])
        }
      })
    } else if (addArray.length > 1) {
      const localArr = addArray.filter((sc, ind) => ind.toString() !== checkId)
      setAddArray([...localArr])
    }
    // setTemplateList([...templateList.filter(x => x.idToAdd !== indexLocal)])
    templateList.map((x) => {
      if (x.idToAdd === indexLocal) {
        templateList.splice(templateList.indexOf(x), 1)
      }
    })
    templateData.map((x) => {
      if (x.id.toString() !== checkId) {
        setTemplateInvalid(false)
      }
    })
    setDeleteModal(false)
  }

  //Function to keep save button disabled if even one template ID is duplicate
  const checkDisabled = () => {
    if (templateList.length > 1) {
      setInvalidArray([])
      templateList.map((x) => {
        templateList.map((y) => {
          if (x.templateId === y.templateId && x.idToAdd !== y.idToAdd) {
            invalidArray.push(y)
          }
          // if (x.templateId === y.templateId && x.idToAdd !== y.idToAdd && x.state === y.state) {
          //   invalidArray.push(y)
          // }
        })
      })
      if (invalidArray.length > 0) {
        setTemplateInvalid(true)
      } else {
        setTemplateInvalid(false)
      }
    }
  }

  const checkIsDeleted = (indexLocal) => {
    const checkId = indexLocal.split("-").pop()
    deleteTemplates.map((x) => {
      if (x.toString() === checkId) {
        return true
      }
      return false
    })
  }

  const handleCancel = () => {
    setDeleteModal(false)
  }

  return (
    <>
      {!isShifted ? (
        <InfoDiv
          body
        >
          <Section>
            <SmallTitle>Title</SmallTitle>
            <Input
              placeholder="Enter the Document Name"
              disabled={!isEdit}
              style={{
                width: 400,
                height: 32,
                background: "#F9F8FF",
                border: 1,
                borderColor: "#D9D9D9",
                borderRadius: 2,
                color: "black",
              }}
              value={name}
              onChange={(e) => {
                setName(e.target.value)
                handleAddTemplate(e.target.value, template, index)
              }}
            />
          </Section>
          <Modal
            title="Delete Template"
            visible={deleteModal}
            onOk={() => handleDelete(index)}
            onCancel={handleCancel}
          >
            Are you sure you want to delete this template?
          </Modal>
          <Section>
            <SmallTitle>Template ID</SmallTitle>
            <Input
              placeholder="Enter the Template ID"
              disabled={!isEdit}
              required
              style={{
                width: 400,
                height: 32,
                background: "#F9F8FF",
                borderThickness: 1,
                borderColor: "#D9D9D9",
                borderRadius: 2,
                color: "black",
              }}
              value={template}
              onChange={(e) => {
                setTemplate(e.target.value)
                handleAddTemplate(name, e.target.value, index)
              }}
            />
            {isInvalidMessage === true ? (
              <>
                <InvalidText>* The Template ID is duplicate, please enter a different Template ID</InvalidText>
              </>
            ) : null}
          </Section>
          {isEdit ? (
            <>
              {checkIsDeleted === true ? (
                <Button
                  type="primary"
                  style={{
                    alignSelf: "center",
                  }}
                  disabled
                >
                  Deleted
                </Button>
              ) : (
                <Button
                  style={{
                    alignSelf: "center",
                  }}
                  onClick={() => setDeleteModal(true)}
                  disabled={!(index.includes("old"))}
                >
                  Delete
                </Button>

              )}
            </>
          ) : null}
        </InfoDiv>

      ) : (
        <InfoDiv
          owner
        >
          <Section>
            <SmallTitle>Title</SmallTitle>
            <Input
              placeholder="Enter the Document Name"
              disabled={!isEdit}
              style={{
                width: 400,
                height: 32,
                background: "#F9F8FF",
                border: 1,
                borderColor: "#D9D9D9",
                borderRadius: 2,
                color: "black",
              }}
              value={name}
              onChange={(e) => {
                setName(e.target.value)
                handleAddTemplate(e.target.value, template, index)
              }}
            />
          </Section>
          <Modal
            title="Delete Template"
            visible={deleteModal}
            onOk={() => handleDelete(index)}
            onCancel={handleCancel}
          >
            Are you sure you want to delete this template?
          </Modal>
          <Section>
            <SmallTitle>Template ID</SmallTitle>
            <Input
              placeholder="Enter the Template ID"
              disabled={!isEdit}
              required
              style={{
                width: 400,
                height: 32,
                background: "#F9F8FF",
                borderThickness: 1,
                borderColor: "#D9D9D9",
                borderRadius: 2,
                color: "black",
              }}
              value={template}
              onChange={(e) => {
                setTemplate(e.target.value)
                handleAddTemplate(name, e.target.value, index)
              }}
            />
            {isInvalidMessage === true ? (
              <InvalidText>* The Template ID is duplicate, please enter a different Template ID</InvalidText>
            ) : null}
          </Section>
          {isEdit ? (
            <>
              {checkIsDeleted === true ? (
                <Button
                  type="primary"
                  style={{
                    alignSelf: "center",
                  }}
                  disabled
                >
                  Deleted
                </Button>
              ) : (
                <Button
                  style={{
                    alignSelf: "center",
                  }}
                  disabled={!(index.includes("old"))}
                  onClick={() => setDeleteModal(true)}
                >
                  Delete
                </Button>
              )}
            </>
          ) : null}
        </InfoDiv>

      )}
    </>
  )
}

const ViewPage = ({
  getOffersDocusignTemplate,
  getDocusignTemplateResponse,
  getOffersTotalCommonTemplate,
  getTotalCountTemplateResponse,
  templateList,
  setTemplateInvalid,
  templateInvalid,
  setTemplateList,
  createOffersDocusignTemplate,
  deleteTemplates,
  setDeleteTemplates,
  deleteOffersDocusignTemplate,
}) => {
  const [isEdit, setIsEdit] = useState(false)

  const [addNewOG, setAddNewOG] = useState(false)
  const [addNewMLS, setAddNewMLS] = useState(false)
  const [addNewCoList, setAddNewColist] = useState(false)
  const [addNewOwner, setAddNewOwner] = useState(false)
  const [addNewSellerOwner, setAddNewSellerOwner] = useState(false)
  const [addNewPurcahseAgreementAddendum, setAddNewPurcahseAgreementAddendum] = useState(false)
  const [addNewFinancingInformation, setAddNewFinancingInformation] = useState(false)
  const [addNewEarnestMoneyDeposit, setAddNewEarnestMoneyDeposit] = useState(false)

  const [addNewKey, setAddNewKey] = useState(false)

  const [addNewOther, setAddNewOther] = useState(false)

  const [addOGDoc, setAddOGDoc] = useState([1])
  const [addMLSDoc, setAddMLSDoc] = useState([1])
  const [addCoListDoc, setAddCoListDoc] = useState([1])
  const [addOwnerDoc, setAddOwnerDoc] = useState([1])
  const [addSellerOwnerDoc, setAddSellerOwnerDoc] = useState([1])
  const [addPurcahseAgreementAddendum, setAddPurcahseAgreementAddendum] = useState([1])
  const [addFinancingInformation, setAddFinancingInformation] = useState([1])
  const [addEarnestMoneyDeposit, setAddEarnestMoneyDeposit] = useState([1])

  const [addKeyDoc, setAddKeyDoc] = useState([1])
  const [addOtherDoc, setAddOtherDoc] = useState([1])
  const [state, setState] = useState("california")

  const [valueListingType, setValueListingType] = useState("residential_1_4")
  const [valueCoList, setValueCoList] = useState("radius")
  const [valueOwner, setValueOwner] = useState("trust")
  const [valueSellerOwner, setValueSellerOwner] = useState("trust")
  const [valuePurcahseAgreementAddendum, setValuePurcahseAgreementAddendum] = useState("no")
  const [valueFinancingInformation, setValueFinancingInformation] = useState("cash")
  const [valueEarnestMoneyDeposit, setValueEarnestMoneyDeposit] = useState("yes")

  const [localTemplateData, setLocalTemplateData] = useState([])
  const [localList, setLocalList] = useState([])

  const [saveModal, setSaveModal] = useState(false)
  const [showSpin, setShowSpin] = useState(false)

  const [addNewSubjectPropertyInProbate, setAddNewSubjectPropertyInProbate] = useState(false)
  const [addSubjectPropertyInProbate, setAddSubjectPropertyInProbate] = useState([1])

  const [addNewIsPropertyShortSale, setAddNewIsPropertyShortSale] = useState(false)
  const [addIsPropertyShortSale, setAddIsPropertyShortSale] = useState([1])

  const [addNewPropertyInForeclosure, setAddNewPropertyInForeclosure] = useState(false)
  const [addPropertyInForeclosure, setAddPropertyInForeclosure] = useState([1])

  const [addNewIncludeContingencyRemoval, setAddNewIncludeContingencyRemoval] = useState(false)
  const [addIncludeContingencyRemoval, setAddIncludeContingencyRemoval] = useState([1])

  const [addNewIncludeSellerInPossession, setAddNewIncludeSellerInPossession] = useState(false)
  const [addIncludeSellerInPossession, setAddIncludeSellerInPossession] = useState([1])

  const [addNewIncludeResidentialLeaseAfterSale, setAddNewIncludeResidentialLeaseAfterSale] = useState(false)
  const [addIncludeResidentialLeaseAfterSale, setAddIncludeResidentialLeaseAfterSale] = useState([1])

  const [addNewPropertyHaveAnHOA, setAddNewPropertyHaveAnHOA] = useState(false)
  const [addPropertyHaveAnHOA, setAddPropertyHaveAnHOA] = useState([1])

  const [addNewSubjectToResidentialLeases, setAddNewSubjectToResidentialLeases] = useState(false)
  const [addSubjectToResidentialLeases, setAddSubjectToResidentialLeases] = useState([1])

  const [addNewSubjectToFixtureLeases, setAddNewSubjectToFixtureLeases] = useState(false)
  const [addSubjectToFixtureLeases, setAddSubjectToFixtureLeases] = useState([1])

  const [addNewBuyerTemporaryResidentialLease, setAddNewBuyerTemporaryResidentialLease] = useState(false)
  const [addBuyerTemporaryResidentialLease, setAddBuyerTemporaryResidentialLease] = useState([1])

  const [addNewAddendumConcerningRightToTerminate, setAddNewAddendumConcerningRightToTerminate] = useState(false)
  const [addAddendumConcerningRightToTerminate, setAddAddendumConcerningRightToTerminate] = useState([1])

  const [addNewPostClosingOccupancyAgreement, setAddNewPostClosingOccupancyAgreement] = useState(false)
  const [addPostClosingOccupancyAgreement, setAddPostClosingOccupancyAgreement] = useState([1])

  const [addNewIncludeCondominumRider, setAddNewIncludeCondominumRider] = useState(false)
  const [addIncludeCondominumRider, setAddIncludeCondominumRider] = useState([1])

  const [addNewIncludeHOAAddendum, setAddNewIncludeHOAAddendum] = useState(false)
  const [addIncludeHOAAddendum, setAddIncludeHOAAddendum] = useState([1])

  const [addNewIncludePostClosingOccupancyAgreement, setAddNewIncludePostClosingOccupancyAgreement] = useState(false)
  const [addIncludePostClosingOccupancyAgreement, setAddIncludePostClosingOccupancyAgreement] = useState([1])

  const [addNewIncludeTemporaryOccupanceAgreement, setAddNewIncludeTemporaryOccupanceAgreement] = useState(false)
  const [addIncludeTemporaryOccupanceAgreement, setAddIncludeTemporaryOccupanceAgreement] = useState([1])

  const [addNewIncludeCondominimumResalePurtchaseAndSaleExhibit, setAddNewIncludeCondominimumResalePurtchaseAndSaleExhibit] = useState(false)
  const [addIncludeCondominimumResalePurtchaseAndSaleExhibit, setAddIncludeCondominimumResalePurtchaseAndSaleExhibit] = useState([1])

  const [addNewIncludeSpecialStipulations, setAddNewIncludeSpecialStipulations] = useState(false)
  const [addIncludeSpecialStipulations, setAddIncludeSpecialStipulations] = useState([1])

  const [addNewIncludeFinancingAddendumToPurchase, setAddNewIncludeFinancingAddendumToPurchase] = useState(false)
  const [addIncludeFinancingAddendumToPurchase, setAddIncludeFinancingAddendumToPurchase] = useState([1])

  const [addNewIncludeOptionalClausesAddendum, setAddNewIncludeOptionalClausesAddendum] = useState(false)
  const [addIncludeOptionalClausesAddendum, setAddIncludeOptionalClausesAddendum] = useState([1])

  const [addNewIncludeTitleContingencyAddendumToPurchase, setAddNewIncludeTitleContingencyAddendumToPurchase] = useState(false)
  const [addIncludeTitleContingencyAddendumToPurchase, setAddIncludeTitleContingencyAddendumToPurchase] = useState([1])

  const [addNewIncludeInspectionAddendumToPurchase, setAddNewIncludeInspectionAddendumToPurchase] = useState(false)
  const [addIncludeInspectionAddendumToPurchase, setAddIncludeInspectionAddendumToPurchase] = useState([1])

  const [addNewIncludeBuyerSalePropertyContingency, setAddNewIncludeBuyerSalePropertyContingency] = useState(false)
  const [addIncludeBuyerSalePropertyContingency, setAddIncludeBuyerSalePropertyContingency] = useState([1])

  const [addNewIncludeRentalAgreementForOccupancyPriorClosing, setAddNewIncludeRentalAgreementForOccupancyPriorClosing] = useState(false)
  const [addIncludeRentalAgreementForOccupancyPriorClosing, setAddIncludeRentalAgreementForOccupancyPriorClosing] = useState([1])

  const [addNewIncludeRentalAgreementForOccupancyAfterClosing, setAddNewIncludeRentalAgreementForOccupancyAfterClosing] = useState(false)
  const [addIncludeRentalAgreementForOccupancyAfterClosing, setAddIncludeRentalAgreementForOccupancyAfterClosing] = useState([1])

  const [addNewIncludePrivateWellAddmToRealEstateSaleAgrmt, setAddNewIncludePrivateWellAddmToRealEstateSaleAgrmt] = useState(false)
  const [addIncludePrivateWellAddmToRealEstateSaleAgrmt, setAddIncludePrivateWellAddmToRealEstateSaleAgrmt] = useState([1])

  const [addNewIncludeSepticOnsiteSewageSystemAddendum, setAddNewIncludeSepticOnsiteSewageSystemAddendum] = useState(false)
  const [addIncludeSepticOnsiteSewageSystemAddendum, setAddIncludeSepticOnsiteSewageSystemAddendum] = useState([1])

  const [addNewIncludeHomeownersAssociationCommunityAddendum, setAddNewIncludeHomeownersAssociationCommunityAddendum] = useState(false)
  const [addIncludeHomeownersAssociationCommunityAddendum, setAddIncludeHomeownersAssociationCommunityAddendum] = useState([1])

  const [addNewIncludeInvestmentPropertyAddendum, setAddNewIncludeInvestmentPropertyAddendum] = useState(false)
  const [addIncludeInvestmentPropertyAddendum, setAddIncludeInvestmentPropertyAddendum] = useState([1])

  const [addNewIncludeAgreementOccupancyPriorToClosing, setAddNewIncludeAgreementOccupancyPriorToClosing] = useState(false)
  const [addIncludeAgreementOccupancyPriorToClosing, setAddIncludeAgreementOccupancyPriorToClosing] = useState([1])

  const [addNewIncludeAgreementOccupancyAfterToClosing, setAddNewIncludeAgreementOccupancyAfterToClosing] = useState(false)
  const [addIncludeAgreementOccupancyAfterToClosing, setAddIncludeAgreementOccupancyAfterToClosing] = useState([1])

  const [addNewIncludeAdvisoryToBuyerWaivingContingencies, setAddNewIncludeAdvisoryToBuyerWaivingContingencies] = useState(false)
  const [addIncludeAdvisoryToBuyerWaivingContingencies, setAddIncludeAdvisoryToBuyerWaivingContingencies] = useState([1])

  const [addNewIncludeSWPI, setAddNewIncludeSWPI] = useState(false)
  const [addIncludeSWPI, setAddIncludeSWPI] = useState([1])

  const [addNewIncludeContingencySaleBuyerProperty, setAddNewIncludeContingencySaleBuyerProperty] = useState(false)
  const [addIncludeContingencySaleBuyerProperty, setAddIncludeContingencySaleBuyerProperty] = useState([1])

  const {
    data: templateData,
    isFetching,
  } = getDocusignTemplateResponse || {}

  const {
    data: result,
  } = getTotalCountTemplateResponse || {}
  
  useEffect(() => {
    getOffersDocusignTemplate()
    getOffersTotalCommonTemplate()
  }, [])

  useEffect(() => {
    if (templateData) {
      templateData.map((x) => {
        templateList.push({
          idToAdd: `old-${x.id}`,
          name: x.name,
          templateId: x.template_id,
          entityName: x.entity_name,
          entityType: x.entity_type,
          state: x.state,
          isEdited: false,
        })
      })
      setLocalTemplateData(templateData)
    }
  }, [templateData])

  const getTotalCountByState = (givenState) => {
    const res = _.find(result, {'state': givenState})
    return res ? res.total : 0
  }

  const addIncludeContingencySaleBuyerPropertyFunc = () => {
    setAddIncludeContingencySaleBuyerProperty([...addIncludeContingencySaleBuyerProperty, addIncludeContingencySaleBuyerProperty.length])
  }

  const addIncludeSWPIFunc = () => {
    setAddIncludeSWPI([...addIncludeSWPI, addIncludeSWPI.length])
  }

  const addIncludeAdvisoryToBuyerWaivingContingenciesFunc = () => {
    setAddIncludeAdvisoryToBuyerWaivingContingencies([...addIncludeAdvisoryToBuyerWaivingContingencies, addIncludeAdvisoryToBuyerWaivingContingencies.length])
  }

  const addIncludeAgreementOccupancyAfterToClosingFunc = () => {
    setAddIncludeAgreementOccupancyAfterToClosing([...addIncludeAgreementOccupancyAfterToClosing, addIncludeAgreementOccupancyAfterToClosing.length])
  }

  const addIncludeAgreementOccupancyPriorToClosingFunc = () => {
    setAddIncludeAgreementOccupancyPriorToClosing([...addIncludeAgreementOccupancyPriorToClosing, addIncludeAgreementOccupancyPriorToClosing.length])
  }

  const addIncludeInvestmentPropertyAddendumFunc = () => {
    setAddIncludeInvestmentPropertyAddendum([...addIncludeInvestmentPropertyAddendum, addIncludeInvestmentPropertyAddendum.length])
  }

  const addIncludeHomeownersAssociationCommunityAddendumFunc = () => {
    setAddIncludeHomeownersAssociationCommunityAddendum([...addIncludeHomeownersAssociationCommunityAddendum, addIncludeHomeownersAssociationCommunityAddendum.length])
  }

  const addIncludeSepticOnsiteSewageSystemAddendumFunc = () => {
    setAddIncludeSepticOnsiteSewageSystemAddendum([...addIncludeSepticOnsiteSewageSystemAddendum, addIncludeSepticOnsiteSewageSystemAddendum.length])
  }

  const addIncludePrivateWellAddmToRealEstateSaleAgrmtFunc = () => {
    setAddIncludePrivateWellAddmToRealEstateSaleAgrmt([...addIncludePrivateWellAddmToRealEstateSaleAgrmt, addIncludePrivateWellAddmToRealEstateSaleAgrmt.length])
  }

  const addIncludeRentalAgreementForOccupancyAfterClosingFunc = () => {
    setAddIncludeRentalAgreementForOccupancyAfterClosing([...addIncludeRentalAgreementForOccupancyAfterClosing, addIncludeRentalAgreementForOccupancyAfterClosing.length])
  }

  const addIncludeRentalAgreementForOccupancyPriorClosingFunc = () => {
    setAddIncludeRentalAgreementForOccupancyPriorClosing([...addIncludeRentalAgreementForOccupancyPriorClosing, addIncludeRentalAgreementForOccupancyPriorClosing.length])
  }

  const addIncludeBuyerSalePropertyContingencyFunc = () => {
    setAddIncludeBuyerSalePropertyContingency([...addIncludeBuyerSalePropertyContingency, addIncludeBuyerSalePropertyContingency.length])
  }

  const addIncludeInspectionAddendumToPurchaseFunc = () => {
    setAddIncludeInspectionAddendumToPurchase([...addIncludeInspectionAddendumToPurchase, addIncludeInspectionAddendumToPurchase.length])
  }

  const addIncludeTitleContingencyAddendumToPurchaseFunc = () => {
    setAddIncludeTitleContingencyAddendumToPurchase([...addIncludeTitleContingencyAddendumToPurchase, addIncludeTitleContingencyAddendumToPurchase.length])
  }

  const addIncludeOptionalClausesAddendumFunc = () => {
    setAddIncludeOptionalClausesAddendum([...addIncludeOptionalClausesAddendum, addIncludeOptionalClausesAddendum.length])
  }

  const addIncludeFinancingAddendumToPurchaseFunc = () => {
    setAddIncludeFinancingAddendumToPurchase([...addIncludeFinancingAddendumToPurchase, addIncludeFinancingAddendumToPurchase.length])
  }

  const addIncludeSpecialStipulationsFunc = () => {
    setAddIncludeSpecialStipulations([...addIncludeSpecialStipulations, addIncludeSpecialStipulations.length])
  }

  const addIncludeCondominimumResalePurtchaseAndSaleExhibitFunc = () => {
    setAddIncludeCondominimumResalePurtchaseAndSaleExhibit([...addIncludeCondominimumResalePurtchaseAndSaleExhibit, addIncludeCondominimumResalePurtchaseAndSaleExhibit.length])
  }

  const addIncludeTemporaryOccupanceAgreementFunc = () => {
    setAddIncludeTemporaryOccupanceAgreement([...addIncludeTemporaryOccupanceAgreement, addIncludeTemporaryOccupanceAgreement.length])
  }

  const addIncludePostClosingOccupancyAgreementFunc = () => {
    setAddIncludePostClosingOccupancyAgreement([...addIncludePostClosingOccupancyAgreement, addIncludePostClosingOccupancyAgreement.length])
  }

  const addIncludeHOAAddendumFunc = () => {
    setAddIncludeHOAAddendum([...addIncludeHOAAddendum, addIncludeHOAAddendum.length])
  }

  const addIncludeCondominumRiderFunc = () => {
    setAddIncludeCondominumRider([...addIncludeCondominumRider, addIncludeCondominumRider.length])
  }

  const addPostClosingOccupancyAgreementFunc = () => {
    setAddPostClosingOccupancyAgreement([...addPostClosingOccupancyAgreement, addPostClosingOccupancyAgreement.length])
  }

  const addAddendumConcerningRightToTerminateFunc = () => {
    setAddAddendumConcerningRightToTerminate([...addAddendumConcerningRightToTerminate, addAddendumConcerningRightToTerminate.length])
  }

  const addBuyerTemporaryResidentialLeaseFunc = () => {
    setAddBuyerTemporaryResidentialLease([...addBuyerTemporaryResidentialLease, addBuyerTemporaryResidentialLease.length])
  }

  const addSubjectToFixtureLeasesFunc = () => {
    setAddSubjectToFixtureLeases([...addSubjectToFixtureLeases, addSubjectToFixtureLeases.length])
  }

  const addSubjectToResidentialLeasesFunc = () => {
    setAddSubjectToResidentialLeases([...addSubjectToResidentialLeases, addSubjectToResidentialLeases.length])
  }

  const addPropertyHaveAnHOAFunc = () => {
    setAddPropertyHaveAnHOA([...addPropertyHaveAnHOA, addPropertyHaveAnHOA.length])
  }

  const addIncludeResidentialLeaseAfterSaleFunc = () => {
    setAddIncludeResidentialLeaseAfterSale([...addIncludeResidentialLeaseAfterSale, addIncludeResidentialLeaseAfterSale.length])
  }

  const addIncludeSellerInPossessionFunc = () => {
    setAddIncludeSellerInPossession([...addIncludeSellerInPossession, addIncludeSellerInPossession.length])
  }

  const addIncludeContingencyRemovalFunc = () => {
    setAddIncludeContingencyRemoval([...addIncludeContingencyRemoval, addIncludeContingencyRemoval.length])
  }

  const addPropertyInForeclosureFunc = () => {
    setAddPropertyInForeclosure([...addPropertyInForeclosure, addPropertyInForeclosure.length])
  }

  const addIsPropertyShortSaleFunc = () => {
    setAddIsPropertyShortSale([...addIsPropertyShortSale, addIsPropertyShortSale.length])
  }

  const addSubjectPropertyInProbateFunc = () => {
    setAddSubjectPropertyInProbate([...addSubjectPropertyInProbate, addSubjectPropertyInProbate.length])
  }

  const addOGDocFunc = () => {
    setAddOGDoc([...addOGDoc, addOGDoc.length])
  }

  const addMLSDocFunc = () => {
    setAddMLSDoc([...addMLSDoc, addMLSDoc.length])
  }

  const addCoListDocFunc = () => {
    setAddCoListDoc([...addCoListDoc, addCoListDoc.length])
  }

  const addOwnerDocFunc = () => {
    setAddOwnerDoc([...addOwnerDoc, addOwnerDoc.length])
  }
  const addSellerOwnerDocFunc = () => {
    setAddSellerOwnerDoc([...addSellerOwnerDoc, addSellerOwnerDoc.length])
  }
  const addPurcahseAgreementAddendumFunc = () => {
    setAddPurcahseAgreementAddendum([...addPurcahseAgreementAddendum, addPurcahseAgreementAddendum.length])
  }
  const addFinancingInformationFunc = () => {
    setAddFinancingInformation([...addFinancingInformation, addFinancingInformation.length])
  }
  const addEarnestMoneyDepositFunc = () => {
    setAddEarnestMoneyDeposit([...addEarnestMoneyDeposit, addEarnestMoneyDeposit.length])
  }

  const addKeyDocFunc = () => {
    setAddKeyDoc([...addKeyDoc, addKeyDoc.length])
  }

  const addOtherDocFunc = () => {
    setAddOtherDoc([...addOtherDoc, addOtherDoc.length])
  }

  const saveCancel = () => {
    setSaveModal(false)
  }

  const transactionType = () => {
    if (state === "california") {
      return [{
        value: "residential_1_4",
        label: "Standard Purchase",
      },
      {
        value: "commercial",
        label: "Commercial",
      },
      {
        value: "lease",
        label: "Lease",
      },
      {
        value: "manufactured_mo",
        label: "Mobile/Manufactured Home",
      },
      {
        value: "residential_inc",
        label: "Residential Income",
      },
      {
        value: "san_francisco",
        label: "San Francisco",
      },
      {
        value: "vacant_land",
        label: "Vacant Land",
      }]
    }
    if (state === "washington") {
      return [{
        value: "residential_1_4",
        label: "Standard Purchase",
      },
      {
        value: "condo_purchase",
        label: "Condo Purchase",
      },
      {
        value: "commercial",
        label: "Commercial",
      },
      {
        value: "manufactured_mo",
        label: "Mobile/Manufactured Home",
      },
      {
        label: "New Construction",
        value: "new_construction",
      },
      {
        value: "vacant_land",
        label: "Vacant Land",
      }]
    }
    if (state === "texas") {
      return [{
        value: "residential_1_4",
        label: "Standard Purchase",
      },
      {
        value: "condo_purchase",
        label: "Condo Purchase",
      },
      {
        value: "commercial_lease",
        label: "Commercial Lease",
      },
      {
        value: "commercial_sale",
        label: "Commercial Sale",
      },
      {
        value: "residential_lease",
        label: "Residential Lease",
      },
      {
        value: "farm_and_ranch",
        label: "Farm and Ranch",
      },
      {
        label: "New Construction (complete)",
        value: "new_construction_completed",
      },
      {
        label: "New Construction (incomplete)",
        value: "new_construction_incomplete",
      },
      {
        value: "vacant_land",
        label: "Vacant Land",
      }]
    }
    if (state === "oregon") {
      return [{
        value: "residential_1_4",
        label: "Standard Purchase",
      },
      {
        value: "commercial",
        label: "Commercial",
      },
      {
        value: "manufactured_mo",
        label: "Mobile/Manufactured Home",
      },
      {
        label: "New Construction",
        value: "new_construction",
      },
      {
        value: "vacant_land",
        label: "Vacant Land",
      }]
    }
    if (state === "georgia") {
      return [{
        value: "residential_1_4",
        label: "Standard Purchase",
      },
      {
        value: "commercial",
        label: "Commercial",
      },
      {
        label: "New Construction",
        value: "new_construction",
      },
      {
        value: "vacant_land",
        label: "Vacant Land",
      }]
    }
    if (state === "florida") {
      return [{
        value: "residential_1_4",
        label: "Standard Purchase",
      },
      {
        value: "lease",
        label: "Lease",
      },
      {
        value: "northeast_florida_purchase",
        label: "Northeast Florida Purchase",
      },
      {
        label: "New Construction",
        value: "new_construction",
      },
      {
        value: "vacant_land",
        label: "Vacant Land",
      }]
    }
    if (state === "colorado") {
      return [{
        value: "residential_1_4",
        label: "Standard Purchase",
      },
      {
        value: "commercial",
        label: "Commercial",
      },
      {
        value: "lease",
        label: "Lease",
      },
      {
        value: "manufactured_mo",
        label: "Mobile/Manufactured Home",
      },
      {
        value: "vacant_land",
        label: "Vacant Land",
      }]
    }
    return [{
      value: "No Value",
      label: "No Value",
    }]
  }

  const checkIfEmpty = () => {
    let isInvalid = null
    if (!isEmpty(templateList)) {
      templateList.find((x) => {
        if ((x.name === "") || (x.templateId === "")) {
          let keyword = null
          if (x.entityType === "transaction_type") keyword = "Type of Listing"
          if (x.entityType === "mls_setup") keyword = "MLS on Market"
          if (x.entityType === "co_listing_agent") keyword = "Co-Listing Agent"
          if (x.entityType === "ownership") keyword = "Ownership"
          if (x.entityType === "other" && x.entityName === "keybox_placement") keyword = "Keysafe / Lockbox"
          if (x.entityType === "other" && x.entityName === "replacement_property_purchase") keyword = "Replacement Property Purchase"
          isInvalid = window.confirm(`Some values for ${keyword} are empty, please fill appropriate details in the empty fields`)
          if (isInvalid) {
            setSaveModal(false)
          }
        }
      })
    }
    return isInvalid
  }

  const saveList = () => {
    const checking = checkIfEmpty() === true || false
    // return
    if (checking === true) {
      setIsEdit(true)
      return
    }
    if (checking === false) {
      if (!isEmpty(templateList)) {
        templateList.forEach((x) => {
          if (x.name !== "" && x.templateId !== "") {
            if (x.isEdited && x.isEdited === true) {
              localList.push(x)
            } else if (!("isEdited" in x)) {
              localList.push(x)
            }
          }
        })
        if (!isEmpty(localList)) {
          createOffersDocusignTemplate({
            payload: localList,
            callback: () => {
              setShowSpin(false)
              root.location.reload()
            },
          })
          setShowSpin(true)
        }
      }
      if (!isEmpty(deleteTemplates)) {
        deleteTemplates.map((id) => {
          deleteOffersDocusignTemplate({
            id,
            callback: () => {
              setShowSpin(false)
              root.location.reload()
            },
          })
        })
        setShowSpin(true)
      }
      if (isEmpty(deleteTemplates) && isEmpty(localList)) {
        root.location.reload()
      }
      setTemplateList([])
      setLocalList([])
      setIsEdit(false)
      setSaveModal(false)
    }
  }
  return (
    <Wrap>
      <TitleWrap>
        <Title body style={{ margin: 0, marginRight: "510px" }}>Offers Document Packet</Title>
        <Button
          style={{
            width: 170,
            background: "#100063",
            color: "white",
          }}

          onClick={() => {
            root.open("/broker/upload-templates-offers")
          }}
        >
                Document Library [
          <span style={{ color: "white" }}>{getTotalCountByState(state)}</span>
]
        </Button>
      </TitleWrap>
      {showSpin === true ? (
        <Spin
          size="large"
          style={{
            alignSelf: "center",
            marginLeft: "25%",
            marginTop: "25%",
          }}
        />
      ) : (
        <Content>
          <Modal
            title="Save Changes"
            visible={saveModal}
            onOk={saveList}
            onCancel={saveCancel}
          >
            Are you sure you want to save these changes?
          </Modal>
          <HeaderWrap>
            <DropdownDiv>
              <Subtitle>State</Subtitle>
              <Select
                defaultValue="California"
                style={{
                  width: 367,
                  marginLeft: 20,
                  marginTop: 0,
                }}
                disabled={isEdit}
                onChange={(value) => {
                  setState(value)
                }}
                options={[
                  {
                    value: "california",
                    label: "California",
                  },
                  {
                    value: "washington",
                    label: "Washington",
                  },
                  {
                    value: "georgia",
                    label: "Georgia",
                  },
                  {
                    value: "texas",
                    label: "Texas",
                  },
                  {
                    value: "florida",
                    label: "Florida",
                  },
                  {
                    value: "colorado",
                    label: "Colorado",
                  },
                  {
                    value: "oregon",
                    label: "Oregon",
                  },
                ]}
              />
            </DropdownDiv>
            {isEdit === false ? (
              <Button
                style={{
                  width: 170,
                  background: "#100063",
                  color: "white",
                }}
                onClick={() => {
                  setIsEdit(true)
                }}
              >
                Edit
              </Button>

            ) : (
              <>
                <Button
                  style={{
                    width: 170,
                    background: "#24AE60",
                    color: "white",
                  }}
                  disabled={templateInvalid}
                  onClick={() => {
                    setSaveModal(true)
                  }}
                >
                  Save
                </Button>
                <Button
                  style={{
                    width: 170,
                    background: "red",
                    color: "white",
                  }}
                  disabled={templateInvalid}
                  onClick={() => {
                    setIsEdit(false)
                  }}
                >
                  Cancel
                </Button>
              </>
            )}
          </HeaderWrap>
          <HR />
          <Body>

            <TitleDiv>
              <Title body>Type of Transaction</Title>
              <Select
                defaultValue="Standard Purchase"
                style={{
                  width: 367,
                  marginLeft: 20,
                  marginTop: 0,
                }}
                onChange={(value) => {
                  setValueListingType(value)
                }}
                options={transactionType()}
              />
            </TitleDiv>
            {isFetching === true ? (
              <Loader />
            ) : (
              <>
                {localTemplateData && !isEmpty(localTemplateData) ? localTemplateData.map(x => (
                  <>
                    {x.entity_type.includes("transaction_type") && x.entity_name === valueListingType ? (
                      <>
                        {x.state === state ? (
                          <TitleAndTemplate
                            index={`old-${x.id}`}
                            deleteTemplates={deleteTemplates}
                            setDeleteTemplates={setDeleteTemplates}
                            templateData={templateData}
                            localTemplateData={localTemplateData}
                            setLocalTemplateData={setLocalTemplateData}
                            isShifted={false}
                            state={state}
                            templateList={templateList}
                            setTemplateList={setTemplateList}
                            entityName={valueListingType}
                            entityType="transaction_type"
                            isEdit={isEdit}
                            addArray={addOGDoc}
                            setAddArray={setAddOGDoc}
                            valueName={x.name}
                            setTemplateInvalid={setTemplateInvalid}
                            valueTemplate={x.template_id}
                          />
                        ) : null}
                      </>
                    ) : null}
                  </>
                )) : (
                  <Text>No documents added for this category yet.</Text>
                )}
              </>
            )}
            {addNewOG === true ? (
              <>
                {addOGDoc.length && addOGDoc.map((x, i) => (
                  <>
                    {i > 0 && (
                      <TitleAndTemplate
                        key={x}
                        addArray={addOGDoc}
                        setAddArray={setAddOGDoc}
                        index={`new-og-${i}`}
                        templateData={templateData}
                        isShifted={false}
                        state={state}
                        templateList={templateList}
                        setTemplateList={setTemplateList}
                        setTemplateInvalid={setTemplateInvalid}
                        entityName={valueListingType}
                        entityType="transaction_type"
                        isEdit={isEdit}
                      />
                    )}
                  </>
                ))}
              </>
            ) : null}
            {isEdit === true ? (
              <AddNewDoc
                onClick={() => {
                  setAddNewOG(true)
                  addOGDocFunc()
                  templateList.push({
                    idToAdd: `new-og-${addOGDoc.length}`,
                    name: "",
                    templateId: "",
                    entityName: valueListingType,
                    entityType: "transaction_type",
                    state,
                  })
                }}
              >
                <Image src={PlusSign} />
                <AddNew>Add New Document </AddNew>
              </AddNewDoc>
            ) : null}
            {/* California START */}
            {state === "california" && (
              <>
                <CoListingDiv>
                  <DropdownDiv>
                    <Subtitle>Co Selling Agent</Subtitle>
                    <Select
                      defaultValue="From Radius"
                      style={{
                        width: 367,
                        marginLeft: 20,
                        marginTop: 0,
                      }}
                      onChange={(value) => {
                        setValueCoList(value)
                      }}
                      options={[
                        {
                          value: "radius",
                          label: "From Radius",
                        },
                        {
                          value: "non_radius",
                          label: "Not From Radius",
                        },
                      ]}
                    />
                  </DropdownDiv>
                  {isFetching === true ? (
                    <Loader />
                  ) : (
                    <>
                      {localTemplateData && !isEmpty(localTemplateData) ? localTemplateData.map(x => (
                        <>
                          {x.entity_type.includes("co_selling_agent") && x.entity_name === valueCoList ? (
                            <>
                              {x.state === state ? (
                                <TitleAndTemplate
                                  index={`old-${x.id}`}
                                  deleteTemplates={deleteTemplates}
                                  setDeleteTemplates={setDeleteTemplates}
                                  templateData={templateData}
                                  localTemplateData={localTemplateData}
                                  setLocalTemplateData={setLocalTemplateData}
                                  addArray={addCoListDoc}
                                  setAddArray={setAddCoListDoc}
                                  isShifted
                                  state={state}
                                  templateList={templateList}
                                  setTemplateInvalid={setTemplateInvalid}
                                  setTemplateList={setTemplateList}
                                  entityName={valueCoList}
                                  entityType="co_selling_agent"
                                  toAdd={addNewCoList}
                                  isEdit={isEdit}
                                  valueName={x.name}
                                  valueTemplate={x.template_id}
                                />
                              ) : null}
                            </>
                          ) : null}
                        </>
                      )) : (
                        <Text>No documents added for this category yet.</Text>
                      )}
                    </>
                  )}
                  {addNewCoList === true ? (
                    <>
                      {addCoListDoc.length && addCoListDoc.map((x, i) => (
                        <>
                          {i > 0 && (
                            <TitleAndTemplate
                              addArray={addCoListDoc}
                              setAddArray={setAddCoListDoc}
                              index={`new-colist-${i}`}
                              templateData={templateData}
                              isShifted
                              state={state}
                              templateList={templateList}
                              setTemplateInvalid={setTemplateInvalid}
                              setTemplateList={setTemplateList}
                              entityName={valueCoList}
                              entityType="co_selling_agent"
                              isEdit={isEdit}
                            />
                          )}
                        </>
                      ))}
                    </>
                  ) : null}
                  {isEdit === true ? (
                    <AddNewDoc
                      onClick={() => {
                        setAddNewColist(true)
                        addCoListDocFunc()
                        templateList.push({
                          idToAdd: `new-colist-${addCoListDoc.length}`,
                          name: "",
                          templateId: "",
                          entityName: valueCoList,
                          entityType: "co_selling_agent",
                          state,
                        })
                      }}
                    >
                      <Image src={PlusSign} />
                      <AddNew>Add New Document </AddNew>
                    </AddNewDoc>
                  ) : null}
                  <HR colist />
                </CoListingDiv>
                <OwnershipDiv>
                  <DropdownDiv>
                    <Subtitle>Buyer Ownership</Subtitle>
                    <Select
                      defaultValue="Trust"
                      style={{
                        width: 367,
                        marginLeft: 20,
                        marginTop: 0,
                      }}
                      onChange={(value) => {
                        setValueOwner(value)
                      }}
                      options={[
                        {
                          value: "trust",
                          label: "Trust",
                        },
                        {
                          value: "successor_trust",
                          label: "Successor Trust",
                        },
                        {
                          value: "entity",
                          label: "Entity",
                        },
                        {
                          value: "probate",
                          label: "Probate",
                        },
                        {
                          value: "power_of_attorney",
                          label: "Power Of Attorney",
                        },
                        {
                          value: "estate",
                          label: "Estate",
                        },
                      ]}
                    />
                  </DropdownDiv>
                  {isFetching === true ? (
                    <Loader />
                  ) : (
                    <>
                      {localTemplateData && !isEmpty(localTemplateData) ? localTemplateData.map(x => (
                        <>
                          {x.entity_type === "ownership" && x.entity_name === valueOwner ? (
                            <>
                              {x.state === state ? (
                                <TitleAndTemplate
                                  index={`old-${x.id}`}
                                  deleteTemplates={deleteTemplates}
                                  setDeleteTemplates={setDeleteTemplates}
                                  templateData={templateData}
                                  localTemplateData={localTemplateData}
                                  setLocalTemplateData={setLocalTemplateData}
                                  addArray={addOwnerDoc}
                                  setAddArray={setAddOwnerDoc}
                                  isShifted
                                  state={state}
                                  templateList={templateList}
                                  setTemplateInvalid={setTemplateInvalid}
                                  setTemplateList={setTemplateList}
                                  entityName={valueOwner}
                                  entityType="ownership"
                                  isEdit={isEdit}
                                  valueName={x.name}
                                  valueTemplate={x.template_id}
                                />
                              ) : null}
                            </>
                          ) : null}
                        </>
                      )) : (
                        <Text>No documents added for this category yet.</Text>
                      )}
                    </>
                  )}
                  {addNewOwner === true ? (
                    <>
                      {addOwnerDoc.length && addOwnerDoc.map((x, i) => (
                        <>
                          {i > 0 && (
                            <TitleAndTemplate
                              addArray={addOwnerDoc}
                              setAddArray={setAddOwnerDoc}
                              index={`new-owner-${i}`}
                              templateData={templateData}
                              isShifted
                              state={state}
                              templateList={templateList}
                              setTemplateInvalid={setTemplateInvalid}
                              setTemplateList={setTemplateList}
                              entityName={valueOwner}
                              entityType="ownership"
                              isEdit={isEdit}
                            />
                          )}
                        </>
                      ))}
                    </>
                  ) : null}
                  {isEdit === true ? (
                    <AddNewDoc
                      onClick={() => {
                        setAddNewOwner(true)
                        addOwnerDocFunc()
                        templateList.push({
                          idToAdd: `new-owner-${addOwnerDoc.length}`,
                          name: "",
                          templateId: "",
                          entityName: valueOwner,
                          entityType: "ownership",
                          state,
                        })
                      }}
                    >
                      <Image src={PlusSign} />
                      <AddNew>Add New Document </AddNew>
                    </AddNewDoc>
                  ) : null}
                  <HR colist />
                </OwnershipDiv>
                <OwnershipDiv>
                  <DropdownDiv>
                    <Subtitle>Seller Ownership</Subtitle>
                    <Select
                      defaultValue="Trust"
                      style={{
                        width: 367,
                        marginLeft: 20,
                        marginTop: 0,
                      }}
                      onChange={(value) => {
                        setValueSellerOwner(value)
                      }}
                      options={[
                        {
                          value: "trust",
                          label: "Trust",
                        },
                        {
                          value: "successor_trust",
                          label: "Successor Trust",
                        },
                        {
                          value: "entity",
                          label: "Entity",
                        },
                        {
                          value: "probate",
                          label: "Probate",
                        },
                        {
                          value: "power_of_attorney",
                          label: "Power Of Attorney",
                        },
                        {
                          value: "estate",
                          label: "Estate",
                        },
                      ]}
                    />
                  </DropdownDiv>
                  {isFetching === true ? (
                    <Loader />
                  ) : (
                    <>
                      {localTemplateData && !isEmpty(localTemplateData) ? localTemplateData.map(x => (
                        <>
                          {x.entity_type.includes("seller_ownership") && x.entity_name === valueSellerOwner ? (
                            <>
                              {x.state === state ? (
                                <TitleAndTemplate
                                  index={`old-${x.id}`}
                                  deleteTemplates={deleteTemplates}
                                  setDeleteTemplates={setDeleteTemplates}
                                  templateData={templateData}
                                  localTemplateData={localTemplateData}
                                  setLocalTemplateData={setLocalTemplateData}
                                  addArray={addSellerOwnerDoc}
                                  setAddArray={setAddSellerOwnerDoc}
                                  isShifted
                                  state={state}
                                  templateList={templateList}
                                  setTemplateInvalid={setTemplateInvalid}
                                  setTemplateList={setTemplateList}
                                  entityName={valueSellerOwner}
                                  entityType="seller_ownership"
                                  isEdit={isEdit}
                                  valueName={x.name}
                                  valueTemplate={x.template_id}
                                />
                              ) : null}
                            </>
                          ) : null}
                        </>
                      )) : (
                        <Text>No documents added for this category yet.</Text>
                      )}
                    </>
                  )}
                  {addNewSellerOwner === true ? (
                    <>
                      {addSellerOwnerDoc.length && addSellerOwnerDoc.map((x, i) => (
                        <>
                          {i > 0 && (
                            <TitleAndTemplate
                              addArray={addSellerOwnerDoc}
                              setAddArray={setAddSellerOwnerDoc}
                              index={`seller_ownership-${i}`}
                              templateData={templateData}
                              isShifted
                              state={state}
                              templateList={templateList}
                              setTemplateInvalid={setTemplateInvalid}
                              setTemplateList={setTemplateList}
                              entityName={valueSellerOwner}
                              entityType="seller_ownership"
                              isEdit={isEdit}
                            />
                          )}
                        </>
                      ))}
                    </>
                  ) : null}
                  {isEdit === true ? (
                    <AddNewDoc
                      onClick={() => {
                        setAddNewSellerOwner(true)
                        addSellerOwnerDocFunc()
                        templateList.push({
                          idToAdd: `seller_ownership-${addSellerOwnerDoc.length}`,
                          name: "",
                          templateId: "",
                          entityName: valueSellerOwner,
                          entityType: "seller_ownership",
                          state,
                        })
                      }}
                    >
                      <Image src={PlusSign} />
                      <AddNew>Add New Document </AddNew>
                    </AddNewDoc>
                  ) : null}
                  <HR colist />
                </OwnershipDiv>
                <KeysafeDiv>
                  <MLSDiv contingency>
                    <Subtitle>Would you like to include a (SWPI) Septic & Well Insption, Property Monument and Propane Tank Allocation of Cost Addendum with your offer?</Subtitle>
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      defaultChecked
                      disabled
                      style={{
                        marginTop: 7,
                      }}
                    />
                  </MLSDiv>
                  {isFetching === true ? (
                    <Loader />
                  ) : (
                    <>
                      {localTemplateData && !isEmpty(localTemplateData) ? localTemplateData.map(x => (
                        <>
                          {x.entity_type.includes("other") && x.entity_name === "include_swpi" ? (
                            <>
                              {x.state === state ? (
                                <TitleAndTemplate
                                  index={`old-${x.id}`}
                                  deleteTemplates={deleteTemplates}
                                  setDeleteTemplates={setDeleteTemplates}
                                  templateData={templateData}
                                  localTemplateData={localTemplateData}
                                  setLocalTemplateData={setLocalTemplateData}
                                  addArray={addIncludeSWPI}
                                  setAddArray={setAddIncludeSWPI}
                                  isShifted
                                  state={state}
                                  templateList={templateList}
                                  setTemplateInvalid={setTemplateInvalid}
                                  setTemplateList={setTemplateList}
                                  entityName="include_swpi"
                                  entityType="other"
                                  isEdit={isEdit}
                                  valueName={x.name}
                                  valueTemplate={x.template_id}
                                />
                              ) : null}
                            </>
                          ) : null}
                        </>
                      )) : (
                        <Text>No documents added for this category yet.</Text>
                      )}
                    </>
                  )}
                  {addNewIncludeSWPI === true ? (
                    <>
                      {addIncludeSWPI.length && addIncludeSWPI.map((x, i) => (
                        <>
                          {i > 0 && (
                            <TitleAndTemplate
                              addArray={addIncludeSWPI}
                              setAddArray={setAddIncludeSWPI}
                              index={`include_swpi-${i}`}
                              templateData={templateData}
                              isShifted
                              state={state}
                              templateList={templateList}
                              setTemplateInvalid={setTemplateInvalid}
                              setTemplateList={setTemplateList}
                              entityName="include_swpi"
                              entityType="other"
                              isEdit={isEdit}
                            />
                          )}
                        </>
                      ))}
                    </>
                  ) : null}
                  {isEdit === true ? (
                    <AddNewDoc
                      onClick={() => {
                        setAddNewIncludeSWPI(true)
                        addIncludeSWPIFunc()
                        templateList.push({
                          idToAdd: `include_swpi-${addIncludeSWPI.length}`,
                          name: "",
                          templateId: "",
                          entityName: "include_swpi",
                          entityType: "other",
                          state,
                        })
                      }}
                    >
                      <Image src={PlusSign} />
                      <AddNew>Add New Document </AddNew>
                    </AddNewDoc>
                  ) : null}
                  <HR last />
                </KeysafeDiv>

                <KeysafeDiv>
                  <MLSDiv contingency>
                    <Subtitle>Would you like to include a Contingency For Sale of Buyer's Property with this offer?</Subtitle>
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      defaultChecked
                      disabled
                      style={{
                        marginTop: 7,
                      }}
                    />
                  </MLSDiv>
                  {isFetching === true ? (
                    <Loader />
                  ) : (
                    <>
                      {localTemplateData && !isEmpty(localTemplateData) ? localTemplateData.map(x => (
                        <>
                          {x.entity_type.includes("other") && x.entity_name === "include_contingency_sale_buyer_property" ? (
                            <>
                              {x.state === state ? (
                                <TitleAndTemplate
                                  index={`old-${x.id}`}
                                  deleteTemplates={deleteTemplates}
                                  setDeleteTemplates={setDeleteTemplates}
                                  templateData={templateData}
                                  localTemplateData={localTemplateData}
                                  setLocalTemplateData={setLocalTemplateData}
                                  addArray={addIncludeContingencySaleBuyerProperty}
                                  setAddArray={setAddIncludeContingencySaleBuyerProperty}
                                  isShifted
                                  state={state}
                                  templateList={templateList}
                                  setTemplateInvalid={setTemplateInvalid}
                                  setTemplateList={setTemplateList}
                                  entityName="include_contingency_sale_buyer_property"
                                  entityType="other"
                                  isEdit={isEdit}
                                  valueName={x.name}
                                  valueTemplate={x.template_id}
                                />
                              ) : null}
                            </>
                          ) : null}
                        </>
                      )) : (
                        <Text>No documents added for this category yet.</Text>
                      )}
                    </>
                  )}
                  {addNewIncludeContingencySaleBuyerProperty === true ? (
                    <>
                      {addIncludeContingencySaleBuyerProperty.length && addIncludeContingencySaleBuyerProperty.map((x, i) => (
                        <>
                          {i > 0 && (
                            <TitleAndTemplate
                              addArray={addIncludeContingencySaleBuyerProperty}
                              setAddArray={setAddIncludeContingencySaleBuyerProperty}
                              index={`include_contingency_sale_buyer_property-${i}`}
                              templateData={templateData}
                              isShifted
                              state={state}
                              templateList={templateList}
                              setTemplateInvalid={setTemplateInvalid}
                              setTemplateList={setTemplateList}
                              entityName="include_contingency_sale_buyer_property"
                              entityType="other"
                              isEdit={isEdit}
                            />
                          )}
                        </>
                      ))}
                    </>
                  ) : null}
                  {isEdit === true ? (
                    <AddNewDoc
                      onClick={() => {
                        setAddNewIncludeContingencySaleBuyerProperty(true)
                        addIncludeContingencySaleBuyerPropertyFunc()
                        templateList.push({
                          idToAdd: `include_contingency_sale_buyer_property-${addIncludeContingencySaleBuyerProperty.length}`,
                          name: "",
                          templateId: "",
                          entityName: "include_contingency_sale_buyer_property",
                          entityType: "other",
                          state,
                        })
                      }}
                    >
                      <Image src={PlusSign} />
                      <AddNew>Add New Document </AddNew>
                    </AddNewDoc>
                  ) : null}
                  <HR last />
                </KeysafeDiv>

                <KeysafeDiv>
                  <MLSDiv contingency>
                    <Subtitle>Is the subject property in Probate?</Subtitle>
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      defaultChecked
                      disabled
                      style={{
                        marginTop: 7,
                      }}
                    />
                  </MLSDiv>
                  {isFetching === true ? (
                    <Loader />
                  ) : (
                    <>
                      {localTemplateData && !isEmpty(localTemplateData) ? localTemplateData.map(x => (
                        <>
                          {x.entity_type.includes("other") && x.entity_name === "subject_property_in_probate" ? (
                            <>
                              {x.state === state ? (
                                <TitleAndTemplate
                                  index={`old-${x.id}`}
                                  deleteTemplates={deleteTemplates}
                                  setDeleteTemplates={setDeleteTemplates}
                                  templateData={templateData}
                                  localTemplateData={localTemplateData}
                                  setLocalTemplateData={setLocalTemplateData}
                                  addArray={addSubjectPropertyInProbate}
                                  setAddArray={setAddSubjectPropertyInProbate}
                                  isShifted
                                  state={state}
                                  templateList={templateList}
                                  setTemplateInvalid={setTemplateInvalid}
                                  setTemplateList={setTemplateList}
                                  entityName="subject_property_in_probate"
                                  entityType="other"
                                  isEdit={isEdit}
                                  valueName={x.name}
                                  valueTemplate={x.template_id}
                                />
                              ) : null}
                            </>
                          ) : null}
                        </>
                      )) : (
                        <Text>No documents added for this category yet.</Text>
                      )}
                    </>
                  )}
                  {addNewSubjectPropertyInProbate === true ? (
                    <>
                      {addSubjectPropertyInProbate.length && addSubjectPropertyInProbate.map((x, i) => (
                        <>
                          {i > 0 && (
                            <TitleAndTemplate
                              addArray={addSubjectPropertyInProbate}
                              setAddArray={setAddSubjectPropertyInProbate}
                              index={`subject_property_in_probate-${i}`}
                              templateData={templateData}
                              isShifted
                              state={state}
                              templateList={templateList}
                              setTemplateInvalid={setTemplateInvalid}
                              setTemplateList={setTemplateList}
                              entityName="subject_property_in_probate"
                              entityType="other"
                              isEdit={isEdit}
                            />
                          )}
                        </>
                      ))}
                    </>
                  ) : null}
                  {isEdit === true ? (
                    <AddNewDoc
                      onClick={() => {
                        setAddNewSubjectPropertyInProbate(true)
                        addSubjectPropertyInProbateFunc()
                        templateList.push({
                          idToAdd: `subject_property_in_probate-${addSubjectPropertyInProbate.length}`,
                          name: "",
                          templateId: "",
                          entityName: "subject_property_in_probate",
                          entityType: "other",
                          state,
                        })
                      }}
                    >
                      <Image src={PlusSign} />
                      <AddNew>Add New Document </AddNew>
                    </AddNewDoc>
                  ) : null}
                  <HR last />
                </KeysafeDiv>

                <KeysafeDiv>
                  <MLSDiv contingency>
                    <Subtitle>Is the subject property a Short Sale?</Subtitle>
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      defaultChecked
                      disabled
                      style={{
                        marginTop: 7,
                      }}
                    />
                  </MLSDiv>
                  {isFetching === true ? (
                    <Loader />
                  ) : (
                    <>
                      {localTemplateData && !isEmpty(localTemplateData) ? localTemplateData.map(x => (
                        <>
                          {x.entity_type.includes("other") && x.entity_name === "is_property_short_sale" ? (
                            <>
                              {x.state === state ? (
                                <TitleAndTemplate
                                  index={`old-${x.id}`}
                                  deleteTemplates={deleteTemplates}
                                  setDeleteTemplates={setDeleteTemplates}
                                  templateData={templateData}
                                  localTemplateData={localTemplateData}
                                  setLocalTemplateData={setLocalTemplateData}
                                  addArray={addIsPropertyShortSale}
                                  setAddArray={setAddIsPropertyShortSale}
                                  isShifted
                                  state={state}
                                  templateList={templateList}
                                  setTemplateInvalid={setTemplateInvalid}
                                  setTemplateList={setTemplateList}
                                  entityName="is_property_short_sale"
                                  entityType="other"
                                  isEdit={isEdit}
                                  valueName={x.name}
                                  valueTemplate={x.template_id}
                                />
                              ) : null}
                            </>
                          ) : null}
                        </>
                      )) : (
                        <Text>No documents added for this category yet.</Text>
                      )}
                    </>
                  )}
                  {addNewIsPropertyShortSale === true ? (
                    <>
                      {addIsPropertyShortSale.length && addIsPropertyShortSale.map((x, i) => (
                        <>
                          {i > 0 && (
                            <TitleAndTemplate
                              addArray={addIsPropertyShortSale}
                              setAddArray={setAddIsPropertyShortSale}
                              index={`is_property_short_sale-${i}`}
                              templateData={templateData}
                              isShifted
                              state={state}
                              templateList={templateList}
                              setTemplateInvalid={setTemplateInvalid}
                              setTemplateList={setTemplateList}
                              entityName="is_property_short_sale"
                              entityType="other"
                              isEdit={isEdit}
                            />
                          )}
                        </>
                      ))}
                    </>
                  ) : null}
                  {isEdit === true ? (
                    <AddNewDoc
                      onClick={() => {
                        setAddNewIsPropertyShortSale(true)
                        addIsPropertyShortSaleFunc()
                        templateList.push({
                          idToAdd: `is_property_short_sale-${addIsPropertyShortSale.length}`,
                          name: "",
                          templateId: "",
                          entityName: "is_property_short_sale",
                          entityType: "other",
                          state,
                        })
                      }}
                    >
                      <Image src={PlusSign} />
                      <AddNew>Add New Document </AddNew>
                    </AddNewDoc>
                  ) : null}
                  <HR last />
                </KeysafeDiv>

                <KeysafeDiv>
                  <MLSDiv contingency>
                    <Subtitle>Is the subject property in Foreclosure?</Subtitle>
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      defaultChecked
                      disabled
                      style={{
                        marginTop: 7,
                      }}
                    />
                  </MLSDiv>
                  {isFetching === true ? (
                    <Loader />
                  ) : (
                    <>
                      {localTemplateData && !isEmpty(localTemplateData) ? localTemplateData.map(x => (
                        <>
                          {x.entity_type.includes("other") && x.entity_name === "property_in_foreclosure" ? (
                            <>
                              {x.state === state ? (
                                <TitleAndTemplate
                                  index={`old-${x.id}`}
                                  deleteTemplates={deleteTemplates}
                                  setDeleteTemplates={setDeleteTemplates}
                                  templateData={templateData}
                                  localTemplateData={localTemplateData}
                                  setLocalTemplateData={setLocalTemplateData}
                                  addArray={addPropertyInForeclosure}
                                  setAddArray={setAddPropertyInForeclosure}
                                  isShifted
                                  state={state}
                                  templateList={templateList}
                                  setTemplateInvalid={setTemplateInvalid}
                                  setTemplateList={setTemplateList}
                                  entityName="property_in_foreclosure"
                                  entityType="other"
                                  isEdit={isEdit}
                                  valueName={x.name}
                                  valueTemplate={x.template_id}
                                />
                              ) : null}
                            </>
                          ) : null}
                        </>
                      )) : (
                        <Text>No documents added for this category yet.</Text>
                      )}
                    </>
                  )}
                  {addNewPropertyInForeclosure === true ? (
                    <>
                      {addPropertyInForeclosure.length && addPropertyInForeclosure.map((x, i) => (
                        <>
                          {i > 0 && (
                            <TitleAndTemplate
                              addArray={addPropertyInForeclosure}
                              setAddArray={setAddPropertyInForeclosure}
                              index={`property_in_foreclosure-${i}`}
                              templateData={templateData}
                              isShifted
                              state={state}
                              templateList={templateList}
                              setTemplateInvalid={setTemplateInvalid}
                              setTemplateList={setTemplateList}
                              entityName="property_in_foreclosure"
                              entityType="other"
                              isEdit={isEdit}
                            />
                          )}
                        </>
                      ))}
                    </>
                  ) : null}
                  {isEdit === true ? (
                    <AddNewDoc
                      onClick={() => {
                        setAddNewPropertyInForeclosure(true)
                        addPropertyInForeclosureFunc()
                        templateList.push({
                          idToAdd: `property_in_foreclosure-${addPropertyInForeclosure.length}`,
                          name: "",
                          templateId: "",
                          entityName: "property_in_foreclosure",
                          entityType: "other",
                          state,
                        })
                      }}
                    >
                      <Image src={PlusSign} />
                      <AddNew>Add New Document </AddNew>
                    </AddNewDoc>
                  ) : null}
                  <HR last />
                </KeysafeDiv>

                <KeysafeDiv>
                  <MLSDiv contingency>
                    <Subtitle>Would you like to include an Contingency Removal with the offer?</Subtitle>
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      defaultChecked
                      disabled
                      style={{
                        marginTop: 7,
                      }}
                    />
                  </MLSDiv>
                  {isFetching === true ? (
                    <Loader />
                  ) : (
                    <>
                      {localTemplateData && !isEmpty(localTemplateData) ? localTemplateData.map(x => (
                        <>
                          {x.entity_type.includes("other") && x.entity_name === "include_contingency_removal" ? (
                            <>
                              {x.state === state ? (
                                <TitleAndTemplate
                                  index={`old-${x.id}`}
                                  deleteTemplates={deleteTemplates}
                                  setDeleteTemplates={setDeleteTemplates}
                                  templateData={templateData}
                                  localTemplateData={localTemplateData}
                                  setLocalTemplateData={setLocalTemplateData}
                                  addArray={addIncludeContingencyRemoval}
                                  setAddArray={setAddIncludeContingencyRemoval}
                                  isShifted
                                  state={state}
                                  templateList={templateList}
                                  setTemplateInvalid={setTemplateInvalid}
                                  setTemplateList={setTemplateList}
                                  entityName="include_contingency_removal"
                                  entityType="other"
                                  isEdit={isEdit}
                                  valueName={x.name}
                                  valueTemplate={x.template_id}
                                />
                              ) : null}
                            </>
                          ) : null}
                        </>
                      )) : (
                        <Text>No documents added for this category yet.</Text>
                      )}
                    </>
                  )}
                  {addNewIncludeContingencyRemoval === true ? (
                    <>
                      {addIncludeContingencyRemoval.length && addIncludeContingencyRemoval.map((x, i) => (
                        <>
                          {i > 0 && (
                            <TitleAndTemplate
                              addArray={addIncludeContingencyRemoval}
                              setAddArray={setAddIncludeContingencyRemoval}
                              index={`include_contingency_removal-${i}`}
                              templateData={templateData}
                              isShifted
                              state={state}
                              templateList={templateList}
                              setTemplateInvalid={setTemplateInvalid}
                              setTemplateList={setTemplateList}
                              entityName="include_contingency_removal"
                              entityType="other"
                              isEdit={isEdit}
                            />
                          )}
                        </>
                      ))}
                    </>
                  ) : null}
                  {isEdit === true ? (
                    <AddNewDoc
                      onClick={() => {
                        setAddNewIncludeContingencyRemoval(true)
                        addIncludeContingencyRemovalFunc()
                        templateList.push({
                          idToAdd: `include_contingency_removal-${addIncludeContingencyRemoval.length}`,
                          name: "",
                          templateId: "",
                          entityName: "include_contingency_removal",
                          entityType: "other",
                          state,
                        })
                      }}
                    >
                      <Image src={PlusSign} />
                      <AddNew>Add New Document </AddNew>
                    </AddNewDoc>
                  ) : null}
                  <HR last />
                </KeysafeDiv>

                <KeysafeDiv>
                  <MLSDiv contingency>
                    <Subtitle>Would you like to include a Seller In Possession with this offer? Reminder: SIP applies if Seller remains on the property for less then 29 days after close of escrow. </Subtitle>
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      defaultChecked
                      disabled
                      style={{
                        marginTop: 7,
                      }}
                    />
                  </MLSDiv>
                  {isFetching === true ? (
                    <Loader />
                  ) : (
                    <>
                      {localTemplateData && !isEmpty(localTemplateData) ? localTemplateData.map(x => (
                        <>
                          {x.entity_type.includes("other") && x.entity_name === "include_seller_in_possession" ? (
                            <>
                              {x.state === state ? (
                                <TitleAndTemplate
                                  index={`old-${x.id}`}
                                  deleteTemplates={deleteTemplates}
                                  setDeleteTemplates={setDeleteTemplates}
                                  templateData={templateData}
                                  localTemplateData={localTemplateData}
                                  setLocalTemplateData={setLocalTemplateData}
                                  addArray={addIncludeSellerInPossession}
                                  setAddArray={setAddIncludeSellerInPossession}
                                  isShifted
                                  state={state}
                                  templateList={templateList}
                                  setTemplateInvalid={setTemplateInvalid}
                                  setTemplateList={setTemplateList}
                                  entityName="include_seller_in_possession"
                                  entityType="other"
                                  isEdit={isEdit}
                                  valueName={x.name}
                                  valueTemplate={x.template_id}
                                />
                              ) : null}
                            </>
                          ) : null}
                        </>
                      )) : (
                        <Text>No documents added for this category yet.</Text>
                      )}
                    </>
                  )}
                  {addNewIncludeSellerInPossession === true ? (
                    <>
                      {addIncludeSellerInPossession.length && addIncludeSellerInPossession.map((x, i) => (
                        <>
                          {i > 0 && (
                            <TitleAndTemplate
                              addArray={addIncludeSellerInPossession}
                              setAddArray={setAddIncludeSellerInPossession}
                              index={`include_seller_in_possession-${i}`}
                              templateData={templateData}
                              isShifted
                              state={state}
                              templateList={templateList}
                              setTemplateInvalid={setTemplateInvalid}
                              setTemplateList={setTemplateList}
                              entityName="include_seller_in_possession"
                              entityType="other"
                              isEdit={isEdit}
                            />
                          )}
                        </>
                      ))}
                    </>
                  ) : null}
                  {isEdit === true ? (
                    <AddNewDoc
                      onClick={() => {
                        setAddNewIncludeSellerInPossession(true)
                        addIncludeSellerInPossessionFunc()
                        templateList.push({
                          idToAdd: `include_seller_in_possession-${addIncludeSellerInPossession.length}`,
                          name: "",
                          templateId: "",
                          entityName: "include_seller_in_possession",
                          entityType: "other",
                          state,
                        })
                      }}
                    >
                      <Image src={PlusSign} />
                      <AddNew>Add New Document </AddNew>
                    </AddNewDoc>
                  ) : null}
                  <HR last />
                </KeysafeDiv>

                <KeysafeDiv>
                  <MLSDiv contingency>
                    <Subtitle>Would you like to include a Residential Lease After Sale with this offer? Reminder: RLAS applies if Seller remains on the property formore than 30 days after close of escrow. </Subtitle>
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      defaultChecked
                      disabled
                      style={{
                        marginTop: 7,
                      }}
                    />
                  </MLSDiv>
                  {isFetching === true ? (
                    <Loader />
                  ) : (
                    <>
                      {localTemplateData && !isEmpty(localTemplateData) ? localTemplateData.map(x => (
                        <>
                          {x.entity_type.includes("other") && x.entity_name === "include_residential_lease_after_sale" ? (
                            <>
                              {x.state === state ? (
                                <TitleAndTemplate
                                  index={`old-${x.id}`}
                                  deleteTemplates={deleteTemplates}
                                  setDeleteTemplates={setDeleteTemplates}
                                  templateData={templateData}
                                  localTemplateData={localTemplateData}
                                  setLocalTemplateData={setLocalTemplateData}
                                  addArray={addIncludeResidentialLeaseAfterSale}
                                  setAddArray={setAddIncludeResidentialLeaseAfterSale}
                                  isShifted
                                  state={state}
                                  templateList={templateList}
                                  setTemplateInvalid={setTemplateInvalid}
                                  setTemplateList={setTemplateList}
                                  entityName="include_residential_lease_after_sale"
                                  entityType="other"
                                  isEdit={isEdit}
                                  valueName={x.name}
                                  valueTemplate={x.template_id}
                                />
                              ) : null}
                            </>
                          ) : null}
                        </>
                      )) : (
                        <Text>No documents added for this category yet.</Text>
                      )}
                    </>
                  )}
                  {addNewIncludeResidentialLeaseAfterSale === true ? (
                    <>
                      {addIncludeResidentialLeaseAfterSale.length && addIncludeResidentialLeaseAfterSale.map((x, i) => (
                        <>
                          {i > 0 && (
                            <TitleAndTemplate
                              addArray={addIncludeResidentialLeaseAfterSale}
                              setAddArray={setAddIncludeResidentialLeaseAfterSale}
                              index={`include_residential_lease_after_sale-${i}`}
                              templateData={templateData}
                              isShifted
                              state={state}
                              templateList={templateList}
                              setTemplateInvalid={setTemplateInvalid}
                              setTemplateList={setTemplateList}
                              entityName="include_residential_lease_after_sale"
                              entityType="other"
                              isEdit={isEdit}
                            />
                          )}
                        </>
                      ))}
                    </>
                  ) : null}
                  {isEdit === true ? (
                    <AddNewDoc
                      onClick={() => {
                        setAddNewIncludeResidentialLeaseAfterSale(true)
                        addIncludeResidentialLeaseAfterSaleFunc()
                        templateList.push({
                          idToAdd: `include_residential_lease_after_sale-${addIncludeResidentialLeaseAfterSale.length}`,
                          name: "",
                          templateId: "",
                          entityName: "include_residential_lease_after_sale",
                          entityType: "other",
                          state,
                        })
                      }}
                    >
                      <Image src={PlusSign} />
                      <AddNew>Add New Document </AddNew>
                    </AddNewDoc>
                  ) : null}
                  <HR last />
                </KeysafeDiv>

                <OwnershipDiv>
                  <DropdownDiv>
                    <Subtitle>Financing Information</Subtitle>
                    <Select
                      defaultValue="cash"
                      style={{
                        width: 367,
                        marginLeft: 20,
                        marginTop: 0,
                      }}
                      onChange={(value) => {
                        setValueFinancingInformation(value)
                      }}
                      options={[
                        {
                          value: "conventional",
                          label: "Conventional",
                        },
                        {
                          value: "cash",
                          label: "Cash",
                        },
                        {
                          value: "FHA",
                          label: "FHA",
                        },
                        {
                          value: "VA",
                          label: "VA",
                        },
                        {
                          value: "seller_financing",
                          label: "Seller Financing",
                        },
                      ]}
                    />
                  </DropdownDiv>
                  {isFetching === true ? (
                    <Loader />
                  ) : (
                    <>
                      {localTemplateData && !isEmpty(localTemplateData) ? localTemplateData.map(x => (
                        <>
                          {x.entity_type.includes("financing_info") && x.entity_name === valueFinancingInformation ? (
                            <>
                              {x.state === state ? (
                                <TitleAndTemplate
                                  index={`old-${x.id}`}
                                  deleteTemplates={deleteTemplates}
                                  setDeleteTemplates={setDeleteTemplates}
                                  templateData={templateData}
                                  localTemplateData={localTemplateData}
                                  setLocalTemplateData={setLocalTemplateData}
                                  addArray={addFinancingInformation}
                                  setAddArray={setAddFinancingInformation}
                                  isShifted
                                  state={state}
                                  templateList={templateList}
                                  setTemplateInvalid={setTemplateInvalid}
                                  setTemplateList={setTemplateList}
                                  entityName={valueFinancingInformation}
                                  entityType="financing_info"
                                  isEdit={isEdit}
                                  valueName={x.name}
                                  valueTemplate={x.template_id}
                                />
                              ) : null}
                            </>
                          ) : null}
                        </>
                      )) : (
                        <Text>No documents added for this category yet.</Text>
                      )}
                    </>
                  )}
                  {addNewFinancingInformation === true ? (
                    <>
                      {addFinancingInformation.length && addFinancingInformation.map((x, i) => (
                        <>
                          {i > 0 && (
                            <TitleAndTemplate
                              addArray={addFinancingInformation}
                              setAddArray={setAddFinancingInformation}
                              index={`financing_info-${i}`}
                              templateData={templateData}
                              isShifted
                              state={state}
                              templateList={templateList}
                              setTemplateInvalid={setTemplateInvalid}
                              setTemplateList={setTemplateList}
                              entityName={valueFinancingInformation}
                              entityType="financing_info"
                              isEdit={isEdit}
                            />
                          )}
                        </>
                      ))}
                    </>
                  ) : null}
                  {isEdit === true ? (
                    <AddNewDoc
                      onClick={() => {
                        setAddNewFinancingInformation(true)
                        addFinancingInformationFunc()
                        templateList.push({
                          idToAdd: `financing_info-${addFinancingInformation.length}`,
                          name: "",
                          templateId: "",
                          entityName: valueFinancingInformation,
                          entityType: "financing_info",
                          state,
                        })
                      }}
                    >
                      <Image src={PlusSign} />
                      <AddNew>Add New Document </AddNew>
                    </AddNewDoc>
                  ) : null}
                  <HR colist />
                </OwnershipDiv>

                <OwnershipDiv>
                  <DropdownDiv>
                    <Subtitle>Would you like to include a Purcahse Agreement Addendum based off of the subject property's city and/or county?</Subtitle>
                    <Select
                      defaultValue="no"
                      style={{
                        width: 1000,
                        marginLeft: 20,
                        marginTop: 0,
                      }}
                      onChange={(value) => {
                        setValuePurcahseAgreementAddendum(value)
                      }}
                      options={[
                        {
                          value: "no",
                          label: "No",
                        },
                        {
                          value: "albany_purchase_agreement_addendum",
                          label: "Albany Purchase Agreement Addendum",
                        },
                        {
                          value: "berkeley_purchase_agreement_addendum",
                          label: "Berkeley Purchase Agreement Addendum",
                        },
                        {
                          value: "castro_valley_purchase_agreement_addendum",
                          label: "Castro Valley Purchase Agreement Addendum",
                        },
                        {
                          value: "city_of_alameda_purchase_agreement_addendum",
                          label: "City of Alameda Purchase Agreement Addendum",
                        },
                        {
                          value: "contra_costa_purchase_agreement_addendum_city_of_el_cerrito",
                          label: "Contra Costa Purchase Agreement Addendum - City of El Cerrito",
                        },
                        {
                          value: "contra_costa_purchase_agreement_addendum_city_of_hercules",
                          label: "Contra Costa Purchase Agreement Addendum - City of Hercules",
                        },
                        {
                          value: "contra_costa_purchase_agreement_addendum_city_of_oakley",
                          label: "Contra Costa Purchase Agreement Addendum - City of Oakley",
                        },
                        {
                          value: "contra_costa_purchase_agreement_addendum_city_of_pinole",
                          label: "Contra Costa Purchase Agreement Addendum - City of Pinole",
                        },
                        {
                          value: "contra_costa_purchase_agreement_addendum_city_of_richmond",
                          label: "Contra Costa Purchase Agreement Addendum - City of Richmond",
                        },
                        {
                          value: "contra_costa_purchase_agreement_addendum_city_of_san_pablo",
                          label: "Contra Costa Purchase Agreement Addendum - City of San Pablo",
                        },
                        {
                          value: "contra_costa_purchase_agreement_addendum_Unincorporated",
                          label: "Contra Costa Purchase Agreement Addendum - Unincorporated (El Sobrante, Kensington, North Richmond, East Richmond Heights, Bay View,East Richmond Heights, Montalvin Manor, Rollingwood and Tara Hills)",
                        },
                        {
                          value: "emeryville_purchase_agreement_addendum",
                          label: "Emeryville Purchase Agreement Addendum",
                        },
                        {
                          value: "oakland_purchase_agreement_addendum",
                          label: "Oakland Purchase Agreement Addendum",
                        },
                        {
                          value: "piedmont_purchase_agreement_addendum",
                          label: "Piedmont Purchase Agreement Addendum",
                        },
                      ]}
                    />
                  </DropdownDiv>
                  {isFetching === true ? (
                    <Loader />
                  ) : (
                    <>
                      {localTemplateData && !isEmpty(localTemplateData) ? localTemplateData.map(x => (
                        <>
                          {x.entity_type.includes("purchase_agreement") && x.entity_name === valuePurcahseAgreementAddendum ? (
                            <>
                              {x.state === state ? (
                                <TitleAndTemplate
                                  index={`old-${x.id}`}
                                  deleteTemplates={deleteTemplates}
                                  setDeleteTemplates={setDeleteTemplates}
                                  templateData={templateData}
                                  localTemplateData={localTemplateData}
                                  setLocalTemplateData={setLocalTemplateData}
                                  addArray={addPurcahseAgreementAddendum}
                                  setAddArray={setAddPurcahseAgreementAddendum}
                                  isShifted
                                  state={state}
                                  templateList={templateList}
                                  setTemplateInvalid={setTemplateInvalid}
                                  setTemplateList={setTemplateList}
                                  entityName={valuePurcahseAgreementAddendum}
                                  entityType="purchase_agreement"
                                  isEdit={isEdit}
                                  valueName={x.name}
                                  valueTemplate={x.template_id}
                                />
                              ) : null}
                            </>
                          ) : null}
                        </>
                      )) : (
                        <Text>No documents added for this category yet.</Text>
                      )}
                    </>
                  )}
                  {addNewPurcahseAgreementAddendum === true ? (
                    <>
                      {addPurcahseAgreementAddendum.length && addPurcahseAgreementAddendum.map((x, i) => (
                        <>
                          {i > 0 && (
                            <TitleAndTemplate
                              addArray={addPurcahseAgreementAddendum}
                              setAddArray={setAddPurcahseAgreementAddendum}
                              index={`purchase_agreement-${i}`}
                              templateData={templateData}
                              isShifted
                              state={state}
                              templateList={templateList}
                              setTemplateInvalid={setTemplateInvalid}
                              setTemplateList={setTemplateList}
                              entityName={valuePurcahseAgreementAddendum}
                              entityType="purchase_agreement"
                              isEdit={isEdit}
                            />
                          )}
                        </>
                      ))}
                    </>
                  ) : null}
                  {isEdit === true ? (
                    <AddNewDoc
                      onClick={() => {
                        setAddNewPurcahseAgreementAddendum(true)
                        addPurcahseAgreementAddendumFunc()
                        templateList.push({
                          idToAdd: `purchase_agreement-${addPurcahseAgreementAddendum.length}`,
                          name: "",
                          templateId: "",
                          entityName: valuePurcahseAgreementAddendum,
                          entityType: "purchase_agreement",
                          state,
                        })
                      }}
                    >
                      <Image src={PlusSign} />
                      <AddNew>Add New Document </AddNew>
                    </AddNewDoc>
                  ) : null}
                  <HR colist />
                </OwnershipDiv>

              </>
            )}

            {/* California END */}

            {/* Texas Start */}
            {state === "texas" && (
              <>
                <OwnershipDiv>
                  <DropdownDiv>
                    <Subtitle>Financing Information</Subtitle>
                    <Select
                      defaultValue="cash"
                      style={{
                        width: 367,
                        marginLeft: 20,
                        marginTop: 0,
                      }}
                      onChange={(value) => {
                        setValueFinancingInformation(value)
                      }}
                      options={[
                        {
                          value: "third_party_financing",
                          label: "Third Party Financing",
                        },
                        {
                          value: "cash",
                          label: "Cash",
                        },
                        {
                          value: "loan_assumption",
                          label: "Loan Assumption",
                        },
                        {
                          value: "seller_financing",
                          label: "Seller Financing",
                        },
                      ]}
                    />
                  </DropdownDiv>
                  {isFetching === true ? (
                    <Loader />
                  ) : (
                    <>
                      {localTemplateData && !isEmpty(localTemplateData) ? localTemplateData.map(x => (
                        <>
                          {x.entity_type.includes("financing_info") && x.entity_name === valueFinancingInformation ? (
                            <>
                              {x.state === state ? (
                                <TitleAndTemplate
                                  index={`old-${x.id}`}
                                  deleteTemplates={deleteTemplates}
                                  setDeleteTemplates={setDeleteTemplates}
                                  templateData={templateData}
                                  localTemplateData={localTemplateData}
                                  setLocalTemplateData={setLocalTemplateData}
                                  addArray={addFinancingInformation}
                                  setAddArray={setAddFinancingInformation}
                                  isShifted
                                  state={state}
                                  templateList={templateList}
                                  setTemplateInvalid={setTemplateInvalid}
                                  setTemplateList={setTemplateList}
                                  entityName={valueFinancingInformation}
                                  entityType="financing_info"
                                  isEdit={isEdit}
                                  valueName={x.name}
                                  valueTemplate={x.template_id}
                                />
                              ) : null}
                            </>
                          ) : null}
                        </>
                      )) : (
                        <Text>No documents added for this category yet.</Text>
                      )}
                    </>
                  )}
                  {addNewFinancingInformation === true ? (
                    <>
                      {addFinancingInformation.length && addFinancingInformation.map((x, i) => (
                        <>
                          {i > 0 && (
                            <TitleAndTemplate
                              addArray={addFinancingInformation}
                              setAddArray={setAddOwnerDoc}
                              index={`financing_info-${i}`}
                              templateData={templateData}
                              isShifted
                              state={state}
                              templateList={templateList}
                              setTemplateInvalid={setTemplateInvalid}
                              setTemplateList={setTemplateList}
                              entityName={valueFinancingInformation}
                              entityType="financing_info"
                              isEdit={isEdit}
                            />
                          )}
                        </>
                      ))}
                    </>
                  ) : null}
                  {isEdit === true ? (
                    <AddNewDoc
                      onClick={() => {
                        setAddNewFinancingInformation(true)
                        addFinancingInformationFunc()
                        templateList.push({
                          idToAdd: `financing_info-${addFinancingInformation.length}`,
                          name: "",
                          templateId: "",
                          entityName: valueFinancingInformation,
                          entityType: "financing_info",
                          state,
                        })
                      }}
                    >
                      <Image src={PlusSign} />
                      <AddNew>Add New Document </AddNew>
                    </AddNewDoc>
                  ) : null}
                  <HR colist />
                </OwnershipDiv>

                <KeysafeDiv>
                  <MLSDiv contingency>
                    <Subtitle>Does the subject property have an HOA? </Subtitle>
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      defaultChecked
                      disabled
                      style={{
                        marginTop: 7,
                      }}
                    />
                  </MLSDiv>
                  {isFetching === true ? (
                    <Loader />
                  ) : (
                    <>
                      {localTemplateData && !isEmpty(localTemplateData) ? localTemplateData.map(x => (
                        <>
                          {x.entity_type.includes("other") && x.entity_name === "property_have_an_hoa" ? (
                            <>
                              {x.state === state ? (
                                <TitleAndTemplate
                                  index={`old-${x.id}`}
                                  deleteTemplates={deleteTemplates}
                                  setDeleteTemplates={setDeleteTemplates}
                                  templateData={templateData}
                                  localTemplateData={localTemplateData}
                                  setLocalTemplateData={setLocalTemplateData}
                                  addArray={addPropertyHaveAnHOA}
                                  setAddArray={setAddPropertyHaveAnHOA}
                                  isShifted
                                  state={state}
                                  templateList={templateList}
                                  setTemplateInvalid={setTemplateInvalid}
                                  setTemplateList={setTemplateList}
                                  entityName="property_have_an_hoa"
                                  entityType="other"
                                  isEdit={isEdit}
                                  valueName={x.name}
                                  valueTemplate={x.template_id}
                                />
                              ) : null}
                            </>
                          ) : null}
                        </>
                      )) : (
                        <Text>No documents added for this category yet.</Text>
                      )}
                    </>
                  )}
                  {addNewPropertyHaveAnHOA === true ? (
                    <>
                      {addPropertyHaveAnHOA.length && addPropertyHaveAnHOA.map((x, i) => (
                        <>
                          {i > 0 && (
                            <TitleAndTemplate
                              addArray={addPropertyHaveAnHOA}
                              setAddArray={setAddPropertyHaveAnHOA}
                              index={`property_have_an_hoa-${i}`}
                              templateData={templateData}
                              isShifted
                              state={state}
                              templateList={templateList}
                              setTemplateInvalid={setTemplateInvalid}
                              setTemplateList={setTemplateList}
                              entityName="property_have_an_hoa"
                              entityType="other"
                              isEdit={isEdit}
                            />
                          )}
                        </>
                      ))}
                    </>
                  ) : null}
                  {isEdit === true ? (
                    <AddNewDoc
                      onClick={() => {
                        setAddNewPropertyHaveAnHOA(true)
                        addPropertyHaveAnHOAFunc()
                        templateList.push({
                          idToAdd: `property_have_an_hoa-${addPropertyHaveAnHOA.length}`,
                          name: "",
                          templateId: "",
                          entityName: "property_have_an_hoa",
                          entityType: "other",
                          state,
                        })
                      }}
                    >
                      <Image src={PlusSign} />
                      <AddNew>Add New Document </AddNew>
                    </AddNewDoc>
                  ) : null}
                  <HR last />
                </KeysafeDiv>
                <KeysafeDiv>
                  <MLSDiv contingency>
                    <Subtitle>Is the property subject to a Residential Leases? </Subtitle>
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      defaultChecked
                      disabled
                      style={{
                        marginTop: 7,
                      }}
                    />
                  </MLSDiv>
                  {isFetching === true ? (
                    <Loader />
                  ) : (
                    <>
                      {localTemplateData && !isEmpty(localTemplateData) ? localTemplateData.map(x => (
                        <>
                          {x.entity_type.includes("other") && x.entity_name === "subject_to_residential_leases" ? (
                            <>
                              {x.state === state ? (
                                <TitleAndTemplate
                                  index={`old-${x.id}`}
                                  deleteTemplates={deleteTemplates}
                                  setDeleteTemplates={setDeleteTemplates}
                                  templateData={templateData}
                                  localTemplateData={localTemplateData}
                                  setLocalTemplateData={setLocalTemplateData}
                                  addArray={addSubjectToResidentialLeases}
                                  setAddArray={setAddSubjectToResidentialLeases}
                                  isShifted
                                  state={state}
                                  templateList={templateList}
                                  setTemplateInvalid={setTemplateInvalid}
                                  setTemplateList={setTemplateList}
                                  entityName="subject_to_residential_leases"
                                  entityType="other"
                                  isEdit={isEdit}
                                  valueName={x.name}
                                  valueTemplate={x.template_id}
                                />
                              ) : null}
                            </>
                          ) : null}
                        </>
                      )) : (
                        <Text>No documents added for this category yet.</Text>
                      )}
                    </>
                  )}
                  {addNewSubjectToResidentialLeases === true ? (
                    <>
                      {addSubjectToResidentialLeases.length && addSubjectToResidentialLeases.map((x, i) => (
                        <>
                          {i > 0 && (
                            <TitleAndTemplate
                              addArray={addSubjectToResidentialLeases}
                              setAddArray={setAddSubjectToResidentialLeases}
                              index={`subject_to_residential_leases-${i}`}
                              templateData={templateData}
                              isShifted
                              state={state}
                              templateList={templateList}
                              setTemplateInvalid={setTemplateInvalid}
                              setTemplateList={setTemplateList}
                              entityName="subject_to_residential_leases"
                              entityType="other"
                              isEdit={isEdit}
                            />
                          )}
                        </>
                      ))}
                    </>
                  ) : null}
                  {isEdit === true ? (
                    <AddNewDoc
                      onClick={() => {
                        setAddNewSubjectToResidentialLeases(true)
                        addSubjectToResidentialLeasesFunc()
                        templateList.push({
                          idToAdd: `subject_to_residential_leases-${addSubjectToResidentialLeases.length}`,
                          name: "",
                          templateId: "",
                          entityName: "subject_to_residential_leases",
                          entityType: "other",
                          state,
                        })
                      }}
                    >
                      <Image src={PlusSign} />
                      <AddNew>Add New Document </AddNew>
                    </AddNewDoc>
                  ) : null}
                  <HR last />
                </KeysafeDiv>
                <KeysafeDiv>
                  <MLSDiv contingency>
                    <Subtitle>Is the property subject to a Fixture Leases? </Subtitle>
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      defaultChecked
                      disabled
                      style={{
                        marginTop: 7,
                      }}
                    />
                  </MLSDiv>
                  {isFetching === true ? (
                    <Loader />
                  ) : (
                    <>
                      {localTemplateData && !isEmpty(localTemplateData) ? localTemplateData.map(x => (
                        <>
                          {x.entity_type.includes("other") && x.entity_name === "subject_to_fixture_leases" ? (
                            <>
                              {x.state === state ? (
                                <TitleAndTemplate
                                  index={`old-${x.id}`}
                                  deleteTemplates={deleteTemplates}
                                  setDeleteTemplates={setDeleteTemplates}
                                  templateData={templateData}
                                  localTemplateData={localTemplateData}
                                  setLocalTemplateData={setLocalTemplateData}
                                  addArray={addSubjectToFixtureLeases}
                                  setAddArray={setAddSubjectToFixtureLeases}
                                  isShifted
                                  state={state}
                                  templateList={templateList}
                                  setTemplateInvalid={setTemplateInvalid}
                                  setTemplateList={setTemplateList}
                                  entityName="subject_to_fixture_leases"
                                  entityType="other"
                                  isEdit={isEdit}
                                  valueName={x.name}
                                  valueTemplate={x.template_id}
                                />
                              ) : null}
                            </>
                          ) : null}
                        </>
                      )) : (
                        <Text>No documents added for this category yet.</Text>
                      )}
                    </>
                  )}
                  {addNewSubjectToFixtureLeases === true ? (
                    <>
                      {addSubjectToFixtureLeases.length && addSubjectToFixtureLeases.map((x, i) => (
                        <>
                          {i > 0 && (
                            <TitleAndTemplate
                              addArray={addSubjectToFixtureLeases}
                              setAddArray={setAddSubjectToFixtureLeases}
                              index={`subject_to_fixture_leases-${i}`}
                              templateData={templateData}
                              isShifted
                              state={state}
                              templateList={templateList}
                              setTemplateInvalid={setTemplateInvalid}
                              setTemplateList={setTemplateList}
                              entityName="subject_to_fixture_leases"
                              entityType="other"
                              isEdit={isEdit}
                            />
                          )}
                        </>
                      ))}
                    </>
                  ) : null}
                  {isEdit === true ? (
                    <AddNewDoc
                      onClick={() => {
                        setAddNewSubjectToFixtureLeases(true)
                        addSubjectToFixtureLeasesFunc()
                        templateList.push({
                          idToAdd: `subject_to_fixture_leases-${addSubjectToFixtureLeases.length}`,
                          name: "",
                          templateId: "",
                          entityName: "subject_to_fixture_leases",
                          entityType: "other",
                          state,
                        })
                      }}
                    >
                      <Image src={PlusSign} />
                      <AddNew>Add New Document </AddNew>
                    </AddNewDoc>
                  ) : null}
                  <HR last />
                </KeysafeDiv>
                <KeysafeDiv>
                  <MLSDiv contingency>
                    <Subtitle>Would you like to include a Buyer's Temporary Residential Lease?  </Subtitle>
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      defaultChecked
                      disabled
                      style={{
                        marginTop: 7,
                      }}
                    />
                  </MLSDiv>
                  {isFetching === true ? (
                    <Loader />
                  ) : (
                    <>
                      {localTemplateData && !isEmpty(localTemplateData) ? localTemplateData.map(x => (
                        <>
                          {x.entity_type.includes("other") && x.entity_name === "buyer_temporary_residential_lease" ? (
                            <>
                              {x.state === state ? (
                                <TitleAndTemplate
                                  index={`old-${x.id}`}
                                  deleteTemplates={deleteTemplates}
                                  setDeleteTemplates={setDeleteTemplates}
                                  templateData={templateData}
                                  localTemplateData={localTemplateData}
                                  setLocalTemplateData={setLocalTemplateData}
                                  addArray={addBuyerTemporaryResidentialLease}
                                  setAddArray={setAddBuyerTemporaryResidentialLease}
                                  isShifted
                                  state={state}
                                  templateList={templateList}
                                  setTemplateInvalid={setTemplateInvalid}
                                  setTemplateList={setTemplateList}
                                  entityName="buyer_temporary_residential_lease"
                                  entityType="other"
                                  isEdit={isEdit}
                                  valueName={x.name}
                                  valueTemplate={x.template_id}
                                />
                              ) : null}
                            </>
                          ) : null}
                        </>
                      )) : (
                        <Text>No documents added for this category yet.</Text>
                      )}
                    </>
                  )}
                  {addNewBuyerTemporaryResidentialLease === true ? (
                    <>
                      {addBuyerTemporaryResidentialLease.length && addBuyerTemporaryResidentialLease.map((x, i) => (
                        <>
                          {i > 0 && (
                            <TitleAndTemplate
                              addArray={addBuyerTemporaryResidentialLease}
                              setAddArray={setAddBuyerTemporaryResidentialLease}
                              index={`buyer_temporary_residential_lease-${i}`}
                              templateData={templateData}
                              isShifted
                              state={state}
                              templateList={templateList}
                              setTemplateInvalid={setTemplateInvalid}
                              setTemplateList={setTemplateList}
                              entityName="buyer_temporary_residential_lease"
                              entityType="other"
                              isEdit={isEdit}
                            />
                          )}
                        </>
                      ))}
                    </>
                  ) : null}
                  {isEdit === true ? (
                    <AddNewDoc
                      onClick={() => {
                        setAddNewBuyerTemporaryResidentialLease(true)
                        addBuyerTemporaryResidentialLeaseFunc()
                        templateList.push({
                          idToAdd: `buyer_temporary_residential_lease-${addBuyerTemporaryResidentialLease.length}`,
                          name: "",
                          templateId: "",
                          entityName: "buyer_temporary_residential_lease",
                          entityType: "other",
                          state,
                        })
                      }}
                    >
                      <Image src={PlusSign} />
                      <AddNew>Add New Document </AddNew>
                    </AddNewDoc>
                  ) : null}
                  <HR last />
                </KeysafeDiv>
                <KeysafeDiv>
                  <MLSDiv contingency>
                    <Subtitle>Would you like to include an Addendum Concerning Right to Terminate Due to Lender's Appraisal?   </Subtitle>
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      defaultChecked
                      disabled
                      style={{
                        marginTop: 7,
                      }}
                    />
                  </MLSDiv>
                  {isFetching === true ? (
                    <Loader />
                  ) : (
                    <>
                      {localTemplateData && !isEmpty(localTemplateData) ? localTemplateData.map(x => (
                        <>
                          {x.entity_type.includes("other") && x.entity_name === "addendum_concerning_right_to_terminate" ? (
                            <>
                              {x.state === state ? (
                                <TitleAndTemplate
                                  index={`old-${x.id}`}
                                  deleteTemplates={deleteTemplates}
                                  setDeleteTemplates={setDeleteTemplates}
                                  templateData={templateData}
                                  localTemplateData={localTemplateData}
                                  setLocalTemplateData={setLocalTemplateData}
                                  addArray={addAddendumConcerningRightToTerminate}
                                  setAddArray={setAddAddendumConcerningRightToTerminate}
                                  isShifted
                                  state={state}
                                  templateList={templateList}
                                  setTemplateInvalid={setTemplateInvalid}
                                  setTemplateList={setTemplateList}
                                  entityName="addendum_concerning_right_to_terminate"
                                  entityType="other"
                                  isEdit={isEdit}
                                  valueName={x.name}
                                  valueTemplate={x.template_id}
                                />
                              ) : null}
                            </>
                          ) : null}
                        </>
                      )) : (
                        <Text>No documents added for this category yet.</Text>
                      )}
                    </>
                  )}
                  {addNewAddendumConcerningRightToTerminate === true ? (
                    <>
                      {addAddendumConcerningRightToTerminate.length && addAddendumConcerningRightToTerminate.map((x, i) => (
                        <>
                          {i > 0 && (
                            <TitleAndTemplate
                              addArray={addAddendumConcerningRightToTerminate}
                              setAddArray={setAddAddendumConcerningRightToTerminate}
                              index={`addendum_concerning_right_to_terminate-${i}`}
                              templateData={templateData}
                              isShifted
                              state={state}
                              templateList={templateList}
                              setTemplateInvalid={setTemplateInvalid}
                              setTemplateList={setTemplateList}
                              entityName="addendum_concerning_right_to_terminate"
                              entityType="other"
                              isEdit={isEdit}
                            />
                          )}
                        </>
                      ))}
                    </>
                  ) : null}
                  {isEdit === true ? (
                    <AddNewDoc
                      onClick={() => {
                        setAddNewAddendumConcerningRightToTerminate(true)
                        addAddendumConcerningRightToTerminateFunc()
                        templateList.push({
                          idToAdd: `addendum_concerning_right_to_terminate-${addAddendumConcerningRightToTerminate.length}`,
                          name: "",
                          templateId: "",
                          entityName: "addendum_concerning_right_to_terminate",
                          entityType: "other",
                          state,
                        })
                      }}
                    >
                      <Image src={PlusSign} />
                      <AddNew>Add New Document </AddNew>
                    </AddNewDoc>
                  ) : null}
                  <HR last />
                </KeysafeDiv>
              </>
            )}

            {/* Texas End */}

            {/* Florida Start */}
            {state === "florida" && (
              <>
                <OwnershipDiv>
                  <DropdownDiv>
                    <Subtitle>Financing Information</Subtitle>
                    <Select
                      defaultValue="cash"
                      style={{
                        width: 367,
                        marginLeft: 20,
                        marginTop: 0,
                      }}
                      onChange={(value) => {
                        setValueFinancingInformation(value)
                      }}
                      options={[
                        {
                          value: "conventional",
                          label: "Conventional",
                        },
                        {
                          value: "cash",
                          label: "Cash",
                        },
                        {
                          value: "FHA",
                          label: "FHA",
                        },
                        {
                          value: "VA",
                          label: "VA",
                        },
                        {
                          value: "assumption_of_existing_mortgage",
                          label: "Assumption of Existing Mortgage",
                        },
                        {
                          value: "purchase_money_note_and_mortgage_to_seller",
                          label: "Purchase Money Note & Mortgage to Seller",
                        },
                      ]}
                    />
                  </DropdownDiv>
                  {isFetching === true ? (
                    <Loader />
                  ) : (
                    <>
                      {localTemplateData && !isEmpty(localTemplateData) ? localTemplateData.map(x => (
                        <>
                          {x.entity_type.includes("financing_info") && x.entity_name === valueFinancingInformation ? (
                            <>
                              {x.state === state ? (
                                <TitleAndTemplate
                                  index={`old-${x.id}`}
                                  deleteTemplates={deleteTemplates}
                                  setDeleteTemplates={setDeleteTemplates}
                                  templateData={templateData}
                                  localTemplateData={localTemplateData}
                                  setLocalTemplateData={setLocalTemplateData}
                                  addArray={addFinancingInformation}
                                  setAddArray={setAddFinancingInformation}
                                  isShifted
                                  state={state}
                                  templateList={templateList}
                                  setTemplateInvalid={setTemplateInvalid}
                                  setTemplateList={setTemplateList}
                                  entityName={valueFinancingInformation}
                                  entityType="financing_info"
                                  isEdit={isEdit}
                                  valueName={x.name}
                                  valueTemplate={x.template_id}
                                />
                              ) : null}
                            </>
                          ) : null}
                        </>
                      )) : (
                        <Text>No documents added for this category yet.</Text>
                      )}
                    </>
                  )}
                  {addNewFinancingInformation === true ? (
                    <>
                      {addFinancingInformation.length && addFinancingInformation.map((x, i) => (
                        <>
                          {i > 0 && (
                            <TitleAndTemplate
                              addArray={addFinancingInformation}
                              setAddArray={setAddFinancingInformation}
                              index={`financing_info-${i}`}
                              templateData={templateData}
                              isShifted
                              state={state}
                              templateList={templateList}
                              setTemplateInvalid={setTemplateInvalid}
                              setTemplateList={setTemplateList}
                              entityName={valueFinancingInformation}
                              entityType="financing_info"
                              isEdit={isEdit}
                            />
                          )}
                        </>
                      ))}
                    </>
                  ) : null}
                  {isEdit === true ? (
                    <AddNewDoc
                      onClick={() => {
                        setAddNewFinancingInformation(true)
                        addFinancingInformationFunc()
                        templateList.push({
                          idToAdd: `financing_info-${addFinancingInformation.length}`,
                          name: "",
                          templateId: "",
                          entityName: valueFinancingInformation,
                          entityType: "financing_info",
                          state,
                        })
                      }}
                    >
                      <Image src={PlusSign} />
                      <AddNew>Add New Document </AddNew>
                    </AddNewDoc>
                  ) : null}
                  <HR colist />
                </OwnershipDiv>

                <KeysafeDiv>
                  <MLSDiv contingency>
                    <Subtitle>Would you like to include a Post Closing Occupancy Agreement with this offer? </Subtitle>
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      defaultChecked
                      disabled
                      style={{
                        marginTop: 7,
                      }}
                    />
                  </MLSDiv>
                  {isFetching === true ? (
                    <Loader />
                  ) : (
                    <>
                      {localTemplateData && !isEmpty(localTemplateData) ? localTemplateData.map(x => (
                        <>
                          {x.entity_type.includes("other") && x.entity_name === "include_post_closing_occupancy_agreement" ? (
                            <>
                              {x.state === state ? (
                                <TitleAndTemplate
                                  index={`old-${x.id}`}
                                  deleteTemplates={deleteTemplates}
                                  setDeleteTemplates={setDeleteTemplates}
                                  templateData={templateData}
                                  localTemplateData={localTemplateData}
                                  setLocalTemplateData={setLocalTemplateData}
                                  addArray={addPostClosingOccupancyAgreement}
                                  setAddArray={setAddPostClosingOccupancyAgreement}
                                  isShifted
                                  state={state}
                                  templateList={templateList}
                                  setTemplateInvalid={setTemplateInvalid}
                                  setTemplateList={setTemplateList}
                                  entityName="include_post_closing_occupancy_agreement"
                                  entityType="other"
                                  isEdit={isEdit}
                                  valueName={x.name}
                                  valueTemplate={x.template_id}
                                />
                              ) : null}
                            </>
                          ) : null}
                        </>
                      )) : (
                        <Text>No documents added for this category yet.</Text>
                      )}
                    </>
                  )}
                  {addNewPostClosingOccupancyAgreement === true ? (
                    <>
                      {addPostClosingOccupancyAgreement.length && addPostClosingOccupancyAgreement.map((x, i) => (
                        <>
                          {i > 0 && (
                            <TitleAndTemplate
                              addArray={addPostClosingOccupancyAgreement}
                              setAddArray={setAddPostClosingOccupancyAgreement}
                              index={`include_post_closing_occupancy_agreement-${i}`}
                              templateData={templateData}
                              isShifted
                              state={state}
                              templateList={templateList}
                              setTemplateInvalid={setTemplateInvalid}
                              setTemplateList={setTemplateList}
                              entityName="include_post_closing_occupancy_agreement"
                              entityType="other"
                              isEdit={isEdit}
                            />
                          )}
                        </>
                      ))}
                    </>
                  ) : null}
                  {isEdit === true ? (
                    <AddNewDoc
                      onClick={() => {
                        setAddNewPostClosingOccupancyAgreement(true)
                        addPostClosingOccupancyAgreementFunc()
                        templateList.push({
                          idToAdd: `include_post_closing_occupancy_agreement-${addPostClosingOccupancyAgreement.length}`,
                          name: "",
                          templateId: "",
                          entityName: "include_post_closing_occupancy_agreement",
                          entityType: "other",
                          state,
                        })
                      }}
                    >
                      <Image src={PlusSign} />
                      <AddNew>Add New Document </AddNew>
                    </AddNewDoc>
                  ) : null}
                  <HR last />
                </KeysafeDiv>
                <KeysafeDiv>
                  <MLSDiv contingency>
                    <Subtitle>Would you like to include a Condominum Rider with this offer?</Subtitle>
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      defaultChecked
                      disabled
                      style={{
                        marginTop: 7,
                      }}
                    />
                  </MLSDiv>
                  {isFetching === true ? (
                    <Loader />
                  ) : (
                    <>
                      {localTemplateData && !isEmpty(localTemplateData) ? localTemplateData.map(x => (
                        <>
                          {x.entity_type.includes("other") && x.entity_name === "include_condominum_rider" ? (
                            <>
                              {x.state === state ? (
                                <TitleAndTemplate
                                  index={`old-${x.id}`}
                                  deleteTemplates={deleteTemplates}
                                  setDeleteTemplates={setDeleteTemplates}
                                  templateData={templateData}
                                  localTemplateData={localTemplateData}
                                  setLocalTemplateData={setLocalTemplateData}
                                  addArray={addIncludeCondominumRider}
                                  setAddArray={setAddIncludeCondominumRider}
                                  isShifted
                                  state={state}
                                  templateList={templateList}
                                  setTemplateInvalid={setTemplateInvalid}
                                  setTemplateList={setTemplateList}
                                  entityName="include_condominum_rider"
                                  entityType="other"
                                  isEdit={isEdit}
                                  valueName={x.name}
                                  valueTemplate={x.template_id}
                                />
                              ) : null}
                            </>
                          ) : null}
                        </>
                      )) : (
                        <Text>No documents added for this category yet.</Text>
                      )}
                    </>
                  )}
                  {addNewIncludeCondominumRider === true ? (
                    <>
                      {addIncludeCondominumRider.length && addIncludeCondominumRider.map((x, i) => (
                        <>
                          {i > 0 && (
                            <TitleAndTemplate
                              addArray={addIncludeCondominumRider}
                              setAddArray={setAddIncludeCondominumRider}
                              index={`include_condominum_rider-${i}`}
                              templateData={templateData}
                              isShifted
                              state={state}
                              templateList={templateList}
                              setTemplateInvalid={setTemplateInvalid}
                              setTemplateList={setTemplateList}
                              entityName="include_condominum_rider"
                              entityType="other"
                              isEdit={isEdit}
                            />
                          )}
                        </>
                      ))}
                    </>
                  ) : null}
                  {isEdit === true ? (
                    <AddNewDoc
                      onClick={() => {
                        setAddNewIncludeCondominumRider(true)
                        addIncludeCondominumRiderFunc()
                        templateList.push({
                          idToAdd: `include_condominum_rider-${addIncludeCondominumRider.length}`,
                          name: "",
                          templateId: "",
                          entityName: "include_condominum_rider",
                          entityType: "other",
                          state,
                        })
                      }}
                    >
                      <Image src={PlusSign} />
                      <AddNew>Add New Document </AddNew>
                    </AddNewDoc>
                  ) : null}
                  <HR last />
                </KeysafeDiv>
                <KeysafeDiv>
                  <MLSDiv contingency>
                    <Subtitle>Would you like to include a HOA Addendum with this offer?</Subtitle>
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      defaultChecked
                      disabled
                      style={{
                        marginTop: 7,
                      }}
                    />
                  </MLSDiv>
                  {isFetching === true ? (
                    <Loader />
                  ) : (
                    <>
                      {localTemplateData && !isEmpty(localTemplateData) ? localTemplateData.map(x => (
                        <>
                          {x.entity_type.includes("other") && x.entity_name === "include_hoa_addendum" ? (
                            <>
                              {x.state === state ? (
                                <TitleAndTemplate
                                  index={`old-${x.id}`}
                                  deleteTemplates={deleteTemplates}
                                  setDeleteTemplates={setDeleteTemplates}
                                  templateData={templateData}
                                  localTemplateData={localTemplateData}
                                  setLocalTemplateData={setLocalTemplateData}
                                  addArray={addIncludeHOAAddendum}
                                  setAddArray={setAddIncludeHOAAddendum}
                                  isShifted
                                  state={state}
                                  templateList={templateList}
                                  setTemplateInvalid={setTemplateInvalid}
                                  setTemplateList={setTemplateList}
                                  entityName="include_hoa_addendum"
                                  entityType="other"
                                  isEdit={isEdit}
                                  valueName={x.name}
                                  valueTemplate={x.template_id}
                                />
                              ) : null}
                            </>
                          ) : null}
                        </>
                      )) : (
                        <Text>No documents added for this category yet.</Text>
                      )}
                    </>
                  )}
                  {addNewIncludeHOAAddendum === true ? (
                    <>
                      {addIncludeHOAAddendum.length && addIncludeHOAAddendum.map((x, i) => (
                        <>
                          {i > 0 && (
                            <TitleAndTemplate
                              addArray={addIncludeHOAAddendum}
                              setAddArray={setAddIncludeHOAAddendum}
                              index={`include_hoa_addendum-${i}`}
                              templateData={templateData}
                              isShifted
                              state={state}
                              templateList={templateList}
                              setTemplateInvalid={setTemplateInvalid}
                              setTemplateList={setTemplateList}
                              entityName="include_hoa_addendum"
                              entityType="other"
                              isEdit={isEdit}
                            />
                          )}
                        </>
                      ))}
                    </>
                  ) : null}
                  {isEdit === true ? (
                    <AddNewDoc
                      onClick={() => {
                        setAddNewIncludeHOAAddendum(true)
                        addIncludeHOAAddendumFunc()
                        templateList.push({
                          idToAdd: `include_hoa_addendum-${addIncludeHOAAddendum.length}`,
                          name: "",
                          templateId: "",
                          entityName: "include_hoa_addendum",
                          entityType: "other",
                          state,
                        })
                      }}
                    >
                      <Image src={PlusSign} />
                      <AddNew>Add New Document </AddNew>
                    </AddNewDoc>
                  ) : null}
                  <HR last />
                </KeysafeDiv>

              </>
            )}
            {/* Florida END */}

            {/* Colorado Start */}
            {state === "colorado" && (
              <>
                <KeysafeDiv>
                  <MLSDiv contingency>
                    <Subtitle>Would you like to include a Post Closing Occupancy Agreement with this offer? </Subtitle>
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      defaultChecked
                      disabled
                      style={{
                        marginTop: 7,
                      }}
                    />
                  </MLSDiv>
                  {isFetching === true ? (
                    <Loader />
                  ) : (
                    <>
                      {localTemplateData && !isEmpty(localTemplateData) ? localTemplateData.map(x => (
                        <>
                          {x.entity_type.includes("other") && x.entity_name === "include_post_closing_occupancy_agreement" ? (
                            <>
                              {x.state === state ? (
                                <TitleAndTemplate
                                  index={`old-${x.id}`}
                                  deleteTemplates={deleteTemplates}
                                  setDeleteTemplates={setDeleteTemplates}
                                  templateData={templateData}
                                  localTemplateData={localTemplateData}
                                  setLocalTemplateData={setLocalTemplateData}
                                  addArray={addIncludePostClosingOccupancyAgreement}
                                  setAddArray={setAddIncludePostClosingOccupancyAgreement}
                                  isShifted
                                  state={state}
                                  templateList={templateList}
                                  setTemplateInvalid={setTemplateInvalid}
                                  setTemplateList={setTemplateList}
                                  entityName="include_post_closing_occupancy_agreement"
                                  entityType="other"
                                  isEdit={isEdit}
                                  valueName={x.name}
                                  valueTemplate={x.template_id}
                                />
                              ) : null}
                            </>
                          ) : null}
                        </>
                      )) : (
                        <Text>No documents added for this category yet.</Text>
                      )}
                    </>
                  )}
                  {addNewIncludePostClosingOccupancyAgreement === true ? (
                    <>
                      {addIncludePostClosingOccupancyAgreement.length && addIncludePostClosingOccupancyAgreement.map((x, i) => (
                        <>
                          {i > 0 && (
                            <TitleAndTemplate
                              addArray={addIncludePostClosingOccupancyAgreement}
                              setAddArray={setAddIncludePostClosingOccupancyAgreement}
                              index={`include_post_closing_occupancy_agreement-${i}`}
                              templateData={templateData}
                              isShifted
                              state={state}
                              templateList={templateList}
                              setTemplateInvalid={setTemplateInvalid}
                              setTemplateList={setTemplateList}
                              entityName="include_post_closing_occupancy_agreement"
                              entityType="other"
                              isEdit={isEdit}
                            />
                          )}
                        </>
                      ))}
                    </>
                  ) : null}
                  {isEdit === true ? (
                    <AddNewDoc
                      onClick={() => {
                        setAddNewIncludePostClosingOccupancyAgreement(true)
                        addIncludePostClosingOccupancyAgreementFunc()
                        templateList.push({
                          idToAdd: `include_post_closing_occupancy_agreement-${addIncludePostClosingOccupancyAgreement.length}`,
                          name: "",
                          templateId: "",
                          entityName: "include_post_closing_occupancy_agreement",
                          entityType: "other",
                          state,
                        })
                      }}
                    >
                      <Image src={PlusSign} />
                      <AddNew>Add New Document </AddNew>
                    </AddNewDoc>
                  ) : null}
                  <HR last />
                </KeysafeDiv>

              </>
            )}
            {/* Colorado END */}

            {/* Georgia Start */}
            {state === "georgia" && (
              <>
                <OwnershipDiv>
                  <DropdownDiv>
                    <Subtitle>Financing Information</Subtitle>
                    <Select
                      defaultValue="cash"
                      style={{
                        width: 367,
                        marginLeft: 20,
                        marginTop: 0,
                      }}
                      onChange={(value) => {
                        setValueFinancingInformation(value)
                      }}
                      options={[
                        {
                          value: "conventional",
                          label: "Conventional",
                        },
                        {
                          value: "cash",
                          label: "Cash",
                        },
                        {
                          value: "FHA",
                          label: "FHA",
                        },
                        {
                          value: "VA",
                          label: "VA",
                        },
                        {
                          value: "loan_assumption",
                          label: "Loan Assumption",
                        },
                        {
                          value: "usda_rd",
                          label: "USDA-RD",
                        },
                      ]}
                    />
                  </DropdownDiv>
                  {isFetching === true ? (
                    <Loader />
                  ) : (
                    <>
                      {localTemplateData && !isEmpty(localTemplateData) ? localTemplateData.map(x => (
                        <>
                          {x.entity_type.includes("financing_info") && x.entity_name === valueFinancingInformation ? (
                            <>
                              {x.state === state ? (
                                <TitleAndTemplate
                                  index={`old-${x.id}`}
                                  deleteTemplates={deleteTemplates}
                                  setDeleteTemplates={setDeleteTemplates}
                                  templateData={templateData}
                                  localTemplateData={localTemplateData}
                                  setLocalTemplateData={setLocalTemplateData}
                                  addArray={addFinancingInformation}
                                  setAddArray={setAddFinancingInformation}
                                  isShifted
                                  state={state}
                                  templateList={templateList}
                                  setTemplateInvalid={setTemplateInvalid}
                                  setTemplateList={setTemplateList}
                                  entityName={valueFinancingInformation}
                                  entityType="financing_info"
                                  isEdit={isEdit}
                                  valueName={x.name}
                                  valueTemplate={x.template_id}
                                />
                              ) : null}
                            </>
                          ) : null}
                        </>
                      )) : (
                        <Text>No documents added for this category yet.</Text>
                      )}
                    </>
                  )}
                  {addNewFinancingInformation === true ? (
                    <>
                      {addFinancingInformation.length && addFinancingInformation.map((x, i) => (
                        <>
                          {i > 0 && (
                            <TitleAndTemplate
                              addArray={addFinancingInformation}
                              setAddArray={setAddFinancingInformation}
                              index={`financing_info-${i}`}
                              templateData={templateData}
                              isShifted
                              state={state}
                              templateList={templateList}
                              setTemplateInvalid={setTemplateInvalid}
                              setTemplateList={setTemplateList}
                              entityName={valueFinancingInformation}
                              entityType="financing_info"
                              isEdit={isEdit}
                            />
                          )}
                        </>
                      ))}
                    </>
                  ) : null}
                  {isEdit === true ? (
                    <AddNewDoc
                      onClick={() => {
                        setAddNewFinancingInformation(true)
                        addFinancingInformationFunc()
                        templateList.push({
                          idToAdd: `financing_info-${addFinancingInformation.length}`,
                          name: "",
                          templateId: "",
                          entityName: valueFinancingInformation,
                          entityType: "financing_info",
                          state,
                        })
                      }}
                    >
                      <Image src={PlusSign} />
                      <AddNew>Add New Document </AddNew>
                    </AddNewDoc>
                  ) : null}
                  <HR colist />
                </OwnershipDiv>
                <OwnershipDiv>
                  <DropdownDiv>
                    <Subtitle>Is the closing firm holding the Earnest Money Deposit?</Subtitle>
                    <Select
                      defaultValue="yes"
                      style={{
                        width: 367,
                        marginLeft: 20,
                        marginTop: 0,
                      }}
                      onChange={(value) => {
                        setValueEarnestMoneyDeposit(value)
                      }}
                      options={[
                        {
                          value: "yes",
                          label: "YES",
                        },
                        {
                          value: "no",
                          label: "NO",
                        },
                      ]}
                    />
                  </DropdownDiv>
                  {isFetching === true ? (
                    <Loader />
                  ) : (
                    <>
                      {localTemplateData && !isEmpty(localTemplateData) ? localTemplateData.map(x => (
                        <>
                          {x.entity_type.includes("earnes_money_deposit") && x.entity_name === valueEarnestMoneyDeposit ? (
                            <>
                              {x.state === state ? (
                                <TitleAndTemplate
                                  index={`old-${x.id}`}
                                  deleteTemplates={deleteTemplates}
                                  setDeleteTemplates={setDeleteTemplates}
                                  templateData={templateData}
                                  localTemplateData={localTemplateData}
                                  setLocalTemplateData={setLocalTemplateData}
                                  addArray={addEarnestMoneyDeposit}
                                  setAddArray={setAddEarnestMoneyDeposit}
                                  isShifted
                                  state={state}
                                  templateList={templateList}
                                  setTemplateInvalid={setTemplateInvalid}
                                  setTemplateList={setTemplateList}
                                  entityName={valueEarnestMoneyDeposit}
                                  entityType="earnes_money_deposit"
                                  isEdit={isEdit}
                                  valueName={x.name}
                                  valueTemplate={x.template_id}
                                />
                              ) : null}
                            </>
                          ) : null}
                        </>
                      )) : (
                        <Text>No documents added for this category yet.</Text>
                      )}
                    </>
                  )}
                  {addNewEarnestMoneyDeposit === true ? (
                    <>
                      {addEarnestMoneyDeposit.length && addEarnestMoneyDeposit.map((x, i) => (
                        <>
                          {i > 0 && (
                            <TitleAndTemplate
                              addArray={addEarnestMoneyDeposit}
                              setAddArray={setAddEarnestMoneyDeposit}
                              index={`earnes_money_deposit-${i}`}
                              templateData={templateData}
                              isShifted
                              state={state}
                              templateList={templateList}
                              setTemplateInvalid={setTemplateInvalid}
                              setTemplateList={setTemplateList}
                              entityName={valueEarnestMoneyDeposit}
                              entityType="earnes_money_deposit"
                              isEdit={isEdit}
                            />
                          )}
                        </>
                      ))}
                    </>
                  ) : null}
                  {isEdit === true ? (
                    <AddNewDoc
                      onClick={() => {
                        setAddNewEarnestMoneyDeposit(true)
                        addEarnestMoneyDepositFunc()
                        templateList.push({
                          idToAdd: `earnes_money_deposit-${addEarnestMoneyDeposit.length}`,
                          name: "",
                          templateId: "",
                          entityName: valueEarnestMoneyDeposit,
                          entityType: "earnes_money_deposit",
                          state,
                        })
                      }}
                    >
                      <Image src={PlusSign} />
                      <AddNew>Add New Document </AddNew>
                    </AddNewDoc>
                  ) : null}
                  <HR colist />
                </OwnershipDiv>

                <KeysafeDiv>
                  <MLSDiv contingency>
                    <Subtitle>Would you like to include a Temporary Occupance Agreement with this offer?  </Subtitle>
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      defaultChecked
                      disabled
                      style={{
                        marginTop: 7,
                      }}
                    />
                  </MLSDiv>
                  {isFetching === true ? (
                    <Loader />
                  ) : (
                    <>
                      {localTemplateData && !isEmpty(localTemplateData) ? localTemplateData.map(x => (
                        <>
                          {x.entity_type.includes("other") && x.entity_name === "include_temporary_occupance_agreement" ? (
                            <>
                              {x.state === state ? (
                                <TitleAndTemplate
                                  index={`old-${x.id}`}
                                  deleteTemplates={deleteTemplates}
                                  setDeleteTemplates={setDeleteTemplates}
                                  templateData={templateData}
                                  localTemplateData={localTemplateData}
                                  setLocalTemplateData={setLocalTemplateData}
                                  addArray={addIncludeTemporaryOccupanceAgreement}
                                  setAddArray={setAddIncludeTemporaryOccupanceAgreement}
                                  isShifted
                                  state={state}
                                  templateList={templateList}
                                  setTemplateInvalid={setTemplateInvalid}
                                  setTemplateList={setTemplateList}
                                  entityName="include_temporary_occupance_agreement"
                                  entityType="other"
                                  isEdit={isEdit}
                                  valueName={x.name}
                                  valueTemplate={x.template_id}
                                />
                              ) : null}
                            </>
                          ) : null}
                        </>
                      )) : (
                        <Text>No documents added for this category yet.</Text>
                      )}
                    </>
                  )}
                  {addNewIncludeTemporaryOccupanceAgreement === true ? (
                    <>
                      {addIncludeTemporaryOccupanceAgreement.length && addIncludeTemporaryOccupanceAgreement.map((x, i) => (
                        <>
                          {i > 0 && (
                            <TitleAndTemplate
                              addArray={addIncludeTemporaryOccupanceAgreement}
                              setAddArray={setAddIncludeTemporaryOccupanceAgreement}
                              index={`include_temporary_occupance_agreement-${i}`}
                              templateData={templateData}
                              isShifted
                              state={state}
                              templateList={templateList}
                              setTemplateInvalid={setTemplateInvalid}
                              setTemplateList={setTemplateList}
                              entityName="include_temporary_occupance_agreement"
                              entityType="other"
                              isEdit={isEdit}
                            />
                          )}
                        </>
                      ))}
                    </>
                  ) : null}
                  {isEdit === true ? (
                    <AddNewDoc
                      onClick={() => {
                        setAddNewIncludeTemporaryOccupanceAgreement(true)
                        addIncludeTemporaryOccupanceAgreementFunc()
                        templateList.push({
                          idToAdd: `include_temporary_occupance_agreement-${addIncludeTemporaryOccupanceAgreement.length}`,
                          name: "",
                          templateId: "",
                          entityName: "include_temporary_occupance_agreement",
                          entityType: "other",
                          state,
                        })
                      }}
                    >
                      <Image src={PlusSign} />
                      <AddNew>Add New Document </AddNew>
                    </AddNewDoc>
                  ) : null}
                  <HR last />
                </KeysafeDiv>
                <KeysafeDiv>
                  <MLSDiv contingency>
                    <Subtitle>Would you like to include a Condominimum Resale Purtchase and Sale Exhibit with this offer?  </Subtitle>
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      defaultChecked
                      disabled
                      style={{
                        marginTop: 7,
                      }}
                    />
                  </MLSDiv>
                  {isFetching === true ? (
                    <Loader />
                  ) : (
                    <>
                      {localTemplateData && !isEmpty(localTemplateData) ? localTemplateData.map(x => (
                        <>
                          {x.entity_type.includes("other") && x.entity_name === "include_condominimum_resale_purtchase_and_sale_exhibit" ? (
                            <>
                              {x.state === state ? (
                                <TitleAndTemplate
                                  index={`old-${x.id}`}
                                  deleteTemplates={deleteTemplates}
                                  setDeleteTemplates={setDeleteTemplates}
                                  templateData={templateData}
                                  localTemplateData={localTemplateData}
                                  setLocalTemplateData={setLocalTemplateData}
                                  addArray={addIncludeCondominimumResalePurtchaseAndSaleExhibit}
                                  setAddArray={setAddIncludeCondominimumResalePurtchaseAndSaleExhibit}
                                  isShifted
                                  state={state}
                                  templateList={templateList}
                                  setTemplateInvalid={setTemplateInvalid}
                                  setTemplateList={setTemplateList}
                                  entityName="include_condominimum_resale_purtchase_and_sale_exhibit"
                                  entityType="other"
                                  isEdit={isEdit}
                                  valueName={x.name}
                                  valueTemplate={x.template_id}
                                />
                              ) : null}
                            </>
                          ) : null}
                        </>
                      )) : (
                        <Text>No documents added for this category yet.</Text>
                      )}
                    </>
                  )}
                  {addNewIncludeCondominimumResalePurtchaseAndSaleExhibit === true ? (
                    <>
                      {addIncludeCondominimumResalePurtchaseAndSaleExhibit.length && addIncludeCondominimumResalePurtchaseAndSaleExhibit.map((x, i) => (
                        <>
                          {i > 0 && (
                            <TitleAndTemplate
                              addArray={addIncludeCondominimumResalePurtchaseAndSaleExhibit}
                              setAddArray={setAddIncludeCondominimumResalePurtchaseAndSaleExhibit}
                              index={`include_condominimum_resale_purtchase_and_sale_exhibit-${i}`}
                              templateData={templateData}
                              isShifted
                              state={state}
                              templateList={templateList}
                              setTemplateInvalid={setTemplateInvalid}
                              setTemplateList={setTemplateList}
                              entityName="include_condominimum_resale_purtchase_and_sale_exhibit"
                              entityType="other"
                              isEdit={isEdit}
                            />
                          )}
                        </>
                      ))}
                    </>
                  ) : null}
                  {isEdit === true ? (
                    <AddNewDoc
                      onClick={() => {
                        setAddNewIncludeCondominimumResalePurtchaseAndSaleExhibit(true)
                        addIncludeCondominimumResalePurtchaseAndSaleExhibitFunc()
                        templateList.push({
                          idToAdd: `include_condominimum_resale_purtchase_and_sale_exhibit-${addIncludeCondominimumResalePurtchaseAndSaleExhibit.length}`,
                          name: "",
                          templateId: "",
                          entityName: "include_condominimum_resale_purtchase_and_sale_exhibit",
                          entityType: "other",
                          state,
                        })
                      }}
                    >
                      <Image src={PlusSign} />
                      <AddNew>Add New Document </AddNew>
                    </AddNewDoc>
                  ) : null}
                  <HR last />
                </KeysafeDiv>
                <KeysafeDiv>
                  <MLSDiv contingency>
                    <Subtitle>Would you like to include a Special Stipulations with this offer?  </Subtitle>
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      defaultChecked
                      disabled
                      style={{
                        marginTop: 7,
                      }}
                    />
                  </MLSDiv>
                  {isFetching === true ? (
                    <Loader />
                  ) : (
                    <>
                      {localTemplateData && !isEmpty(localTemplateData) ? localTemplateData.map(x => (
                        <>
                          {x.entity_type.includes("other") && x.entity_name === "include_special_stipulations" ? (
                            <>
                              {x.state === state ? (
                                <TitleAndTemplate
                                  index={`old-${x.id}`}
                                  deleteTemplates={deleteTemplates}
                                  setDeleteTemplates={setDeleteTemplates}
                                  templateData={templateData}
                                  localTemplateData={localTemplateData}
                                  setLocalTemplateData={setLocalTemplateData}
                                  addArray={addIncludeSpecialStipulations}
                                  setAddArray={setAddIncludeSpecialStipulations}
                                  isShifted
                                  state={state}
                                  templateList={templateList}
                                  setTemplateInvalid={setTemplateInvalid}
                                  setTemplateList={setTemplateList}
                                  entityName="include_special_stipulations"
                                  entityType="other"
                                  isEdit={isEdit}
                                  valueName={x.name}
                                  valueTemplate={x.template_id}
                                />
                              ) : null}
                            </>
                          ) : null}
                        </>
                      )) : (
                        <Text>No documents added for this category yet.</Text>
                      )}
                    </>
                  )}
                  {addNewIncludeSpecialStipulations === true ? (
                    <>
                      {addIncludeSpecialStipulations.length && addIncludeSpecialStipulations.map((x, i) => (
                        <>
                          {i > 0 && (
                            <TitleAndTemplate
                              addArray={addIncludeSpecialStipulations}
                              setAddArray={setAddIncludeSpecialStipulations}
                              index={`include_special_stipulations-${i}`}
                              templateData={templateData}
                              isShifted
                              state={state}
                              templateList={templateList}
                              setTemplateInvalid={setTemplateInvalid}
                              setTemplateList={setTemplateList}
                              entityName="include_special_stipulations"
                              entityType="other"
                              isEdit={isEdit}
                            />
                          )}
                        </>
                      ))}
                    </>
                  ) : null}
                  {isEdit === true ? (
                    <AddNewDoc
                      onClick={() => {
                        setAddNewIncludeSpecialStipulations(true)
                        addIncludeSpecialStipulationsFunc()
                        templateList.push({
                          idToAdd: `include_special_stipulations-${addIncludeSpecialStipulations.length}`,
                          name: "",
                          templateId: "",
                          entityName: "include_special_stipulations",
                          entityType: "other",
                          state,
                        })
                      }}
                    >
                      <Image src={PlusSign} />
                      <AddNew>Add New Document </AddNew>
                    </AddNewDoc>
                  ) : null}
                  <HR last />
                </KeysafeDiv>

              </>
            )}
            {/* Georgia END */}

            {/* Washington START */}
            {state === "washington" && (
              <>
                <KeysafeDiv>
                  <MLSDiv contingency>
                    <Subtitle>Would you like to include a FINANCING ADDENDUM TO PURCHASE & SALE AGREEMENT with this offer?   </Subtitle>
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      defaultChecked
                      disabled
                      style={{
                        marginTop: 7,
                      }}
                    />
                  </MLSDiv>
                  {isFetching === true ? (
                    <Loader />
                  ) : (
                    <>
                      {localTemplateData && !isEmpty(localTemplateData) ? localTemplateData.map(x => (
                        <>
                          {x.entity_type.includes("other") && x.entity_name === "include_financing_addendum_to_purchase" ? (
                            <>
                              {x.state === state ? (
                                <TitleAndTemplate
                                  index={`old-${x.id}`}
                                  deleteTemplates={deleteTemplates}
                                  setDeleteTemplates={setDeleteTemplates}
                                  templateData={templateData}
                                  localTemplateData={localTemplateData}
                                  setLocalTemplateData={setLocalTemplateData}
                                  addArray={addIncludeFinancingAddendumToPurchase}
                                  setAddArray={setAddIncludeFinancingAddendumToPurchase}
                                  isShifted
                                  state={state}
                                  templateList={templateList}
                                  setTemplateInvalid={setTemplateInvalid}
                                  setTemplateList={setTemplateList}
                                  entityName="include_financing_addendum_to_purchase"
                                  entityType="other"
                                  isEdit={isEdit}
                                  valueName={x.name}
                                  valueTemplate={x.template_id}
                                />
                              ) : null}
                            </>
                          ) : null}
                        </>
                      )) : (
                        <Text>No documents added for this category yet.</Text>
                      )}
                    </>
                  )}
                  {addNewIncludeFinancingAddendumToPurchase === true ? (
                    <>
                      {addIncludeFinancingAddendumToPurchase.length && addIncludeFinancingAddendumToPurchase.map((x, i) => (
                        <>
                          {i > 0 && (
                            <TitleAndTemplate
                              addArray={addIncludeFinancingAddendumToPurchase}
                              setAddArray={setAddIncludeFinancingAddendumToPurchase}
                              index={`include_financing_addendum_to_purchase-${i}`}
                              templateData={templateData}
                              isShifted
                              state={state}
                              templateList={templateList}
                              setTemplateInvalid={setTemplateInvalid}
                              setTemplateList={setTemplateList}
                              entityName="include_financing_addendum_to_purchase"
                              entityType="other"
                              isEdit={isEdit}
                            />
                          )}
                        </>
                      ))}
                    </>
                  ) : null}
                  {isEdit === true ? (
                    <AddNewDoc
                      onClick={() => {
                        setAddNewIncludeFinancingAddendumToPurchase(true)
                        addIncludeFinancingAddendumToPurchaseFunc()
                        templateList.push({
                          idToAdd: `include_financing_addendum_to_purchase-${addIncludeFinancingAddendumToPurchase.length}`,
                          name: "",
                          templateId: "",
                          entityName: "include_financing_addendum_to_purchase",
                          entityType: "other",
                          state,
                        })
                      }}
                    >
                      <Image src={PlusSign} />
                      <AddNew>Add New Document </AddNew>
                    </AddNewDoc>
                  ) : null}
                  <HR last />
                </KeysafeDiv>
                <KeysafeDiv>
                  <MLSDiv contingency>
                    <Subtitle>Would you like to include a OPTIONAL CLAUSES ADDENDUM TO PURCHASE & SALE AGREEMENT with this offer? </Subtitle>
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      defaultChecked
                      disabled
                      style={{
                        marginTop: 7,
                      }}
                    />
                  </MLSDiv>
                  {isFetching === true ? (
                    <Loader />
                  ) : (
                    <>
                      {localTemplateData && !isEmpty(localTemplateData) ? localTemplateData.map(x => (
                        <>
                          {x.entity_type.includes("other") && x.entity_name === "include_optional_clauses_addendum" ? (
                            <>
                              {x.state === state ? (
                                <TitleAndTemplate
                                  index={`old-${x.id}`}
                                  deleteTemplates={deleteTemplates}
                                  setDeleteTemplates={setDeleteTemplates}
                                  templateData={templateData}
                                  localTemplateData={localTemplateData}
                                  setLocalTemplateData={setLocalTemplateData}
                                  addArray={addIncludeOptionalClausesAddendum}
                                  setAddArray={setAddIncludeOptionalClausesAddendum}
                                  isShifted
                                  state={state}
                                  templateList={templateList}
                                  setTemplateInvalid={setTemplateInvalid}
                                  setTemplateList={setTemplateList}
                                  entityName="include_optional_clauses_addendum"
                                  entityType="other"
                                  isEdit={isEdit}
                                  valueName={x.name}
                                  valueTemplate={x.template_id}
                                />
                              ) : null}
                            </>
                          ) : null}
                        </>
                      )) : (
                        <Text>No documents added for this category yet.</Text>
                      )}
                    </>
                  )}
                  {addNewIncludeOptionalClausesAddendum === true ? (
                    <>
                      {addIncludeOptionalClausesAddendum.length && addIncludeOptionalClausesAddendum.map((x, i) => (
                        <>
                          {i > 0 && (
                            <TitleAndTemplate
                              addArray={addIncludeOptionalClausesAddendum}
                              setAddArray={setAddIncludeOptionalClausesAddendum}
                              index={`include_optional_clauses_addendum-${i}`}
                              templateData={templateData}
                              isShifted
                              state={state}
                              templateList={templateList}
                              setTemplateInvalid={setTemplateInvalid}
                              setTemplateList={setTemplateList}
                              entityName="include_optional_clauses_addendum"
                              entityType="other"
                              isEdit={isEdit}
                            />
                          )}
                        </>
                      ))}
                    </>
                  ) : null}
                  {isEdit === true ? (
                    <AddNewDoc
                      onClick={() => {
                        setAddNewIncludeOptionalClausesAddendum(true)
                        addIncludeOptionalClausesAddendumFunc()
                        templateList.push({
                          idToAdd: `include_optional_clauses_addendum-${addIncludeOptionalClausesAddendum.length}`,
                          name: "",
                          templateId: "",
                          entityName: "include_optional_clauses_addendum",
                          entityType: "other",
                          state,
                        })
                      }}
                    >
                      <Image src={PlusSign} />
                      <AddNew>Add New Document </AddNew>
                    </AddNewDoc>
                  ) : null}
                  <HR last />
                </KeysafeDiv>
                <KeysafeDiv>
                  <MLSDiv contingency>
                    <Subtitle>Would you like to include a TITLE CONTINGENCY ADDENDUM TO PURCHASE & SALE AGREEMENT with this offer? </Subtitle>
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      defaultChecked
                      disabled
                      style={{
                        marginTop: 7,
                      }}
                    />
                  </MLSDiv>
                  {isFetching === true ? (
                    <Loader />
                  ) : (
                    <>
                      {localTemplateData && !isEmpty(localTemplateData) ? localTemplateData.map(x => (
                        <>
                          {x.entity_type.includes("other") && x.entity_name === "include_title_contingency_addendum_to_purchase" ? (
                            <>
                              {x.state === state ? (
                                <TitleAndTemplate
                                  index={`old-${x.id}`}
                                  deleteTemplates={deleteTemplates}
                                  setDeleteTemplates={setDeleteTemplates}
                                  templateData={templateData}
                                  localTemplateData={localTemplateData}
                                  setLocalTemplateData={setLocalTemplateData}
                                  addArray={addIncludeTitleContingencyAddendumToPurchase}
                                  setAddArray={setAddIncludeTitleContingencyAddendumToPurchase}
                                  isShifted
                                  state={state}
                                  templateList={templateList}
                                  setTemplateInvalid={setTemplateInvalid}
                                  setTemplateList={setTemplateList}
                                  entityName="include_title_contingency_addendum_to_purchase"
                                  entityType="other"
                                  isEdit={isEdit}
                                  valueName={x.name}
                                  valueTemplate={x.template_id}
                                />
                              ) : null}
                            </>
                          ) : null}
                        </>
                      )) : (
                        <Text>No documents added for this category yet.</Text>
                      )}
                    </>
                  )}
                  {addNewIncludeTitleContingencyAddendumToPurchase === true ? (
                    <>
                      {addIncludeTitleContingencyAddendumToPurchase.length && addIncludeTitleContingencyAddendumToPurchase.map((x, i) => (
                        <>
                          {i > 0 && (
                            <TitleAndTemplate
                              addArray={addIncludeTitleContingencyAddendumToPurchase}
                              setAddArray={setAddIncludeTitleContingencyAddendumToPurchase}
                              index={`include_title_contingency_addendum_to_purchase-${i}`}
                              templateData={templateData}
                              isShifted
                              state={state}
                              templateList={templateList}
                              setTemplateInvalid={setTemplateInvalid}
                              setTemplateList={setTemplateList}
                              entityName="include_title_contingency_addendum_to_purchase"
                              entityType="other"
                              isEdit={isEdit}
                            />
                          )}
                        </>
                      ))}
                    </>
                  ) : null}
                  {isEdit === true ? (
                    <AddNewDoc
                      onClick={() => {
                        setAddNewIncludeTitleContingencyAddendumToPurchase(true)
                        addIncludeTitleContingencyAddendumToPurchaseFunc()
                        templateList.push({
                          idToAdd: `include_title_contingency_addendum_to_purchase-${addIncludeTitleContingencyAddendumToPurchase.length}`,
                          name: "",
                          templateId: "",
                          entityName: "include_title_contingency_addendum_to_purchase",
                          entityType: "other",
                          state,
                        })
                      }}
                    >
                      <Image src={PlusSign} />
                      <AddNew>Add New Document </AddNew>
                    </AddNewDoc>
                  ) : null}
                  <HR last />
                </KeysafeDiv>
                <KeysafeDiv>
                  <MLSDiv contingency>
                    <Subtitle>Would you like to include a INSPECTION ADDENDUM TO PURCHASE AND SALE AGREEMENT with this offer? </Subtitle>
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      defaultChecked
                      disabled
                      style={{
                        marginTop: 7,
                      }}
                    />
                  </MLSDiv>
                  {isFetching === true ? (
                    <Loader />
                  ) : (
                    <>
                      {localTemplateData && !isEmpty(localTemplateData) ? localTemplateData.map(x => (
                        <>
                          {x.entity_type.includes("other") && x.entity_name === "include_inspection_addendum_to_purchase" ? (
                            <>
                              {x.state === state ? (
                                <TitleAndTemplate
                                  index={`old-${x.id}`}
                                  deleteTemplates={deleteTemplates}
                                  setDeleteTemplates={setDeleteTemplates}
                                  templateData={templateData}
                                  localTemplateData={localTemplateData}
                                  setLocalTemplateData={setLocalTemplateData}
                                  addArray={addIncludeInspectionAddendumToPurchase}
                                  setAddArray={setAddIncludeInspectionAddendumToPurchase}
                                  isShifted
                                  state={state}
                                  templateList={templateList}
                                  setTemplateInvalid={setTemplateInvalid}
                                  setTemplateList={setTemplateList}
                                  entityName="include_inspection_addendum_to_purchase"
                                  entityType="other"
                                  isEdit={isEdit}
                                  valueName={x.name}
                                  valueTemplate={x.template_id}
                                />
                              ) : null}
                            </>
                          ) : null}
                        </>
                      )) : (
                        <Text>No documents added for this category yet.</Text>
                      )}
                    </>
                  )}
                  {addNewIncludeInspectionAddendumToPurchase === true ? (
                    <>
                      {addIncludeInspectionAddendumToPurchase.length && addIncludeInspectionAddendumToPurchase.map((x, i) => (
                        <>
                          {i > 0 && (
                            <TitleAndTemplate
                              addArray={addIncludeInspectionAddendumToPurchase}
                              setAddArray={setAddIncludeInspectionAddendumToPurchase}
                              index={`include_inspection_addendum_to_purchase-${i}`}
                              templateData={templateData}
                              isShifted
                              state={state}
                              templateList={templateList}
                              setTemplateInvalid={setTemplateInvalid}
                              setTemplateList={setTemplateList}
                              entityName="include_inspection_addendum_to_purchase"
                              entityType="other"
                              isEdit={isEdit}
                            />
                          )}
                        </>
                      ))}
                    </>
                  ) : null}
                  {isEdit === true ? (
                    <AddNewDoc
                      onClick={() => {
                        setAddNewIncludeInspectionAddendumToPurchase(true)
                        addIncludeInspectionAddendumToPurchaseFunc()
                        templateList.push({
                          idToAdd: `include_inspection_addendum_to_purchase-${addIncludeInspectionAddendumToPurchase.length}`,
                          name: "",
                          templateId: "",
                          entityName: "include_inspection_addendum_to_purchase",
                          entityType: "other",
                          state,
                        })
                      }}
                    >
                      <Image src={PlusSign} />
                      <AddNew>Add New Document </AddNew>
                    </AddNewDoc>
                  ) : null}
                  <HR last />
                </KeysafeDiv>
                <KeysafeDiv>
                  <MLSDiv contingency>
                    <Subtitle>Would you like to include a Buyer Sale of Property Contingency with this offer?  </Subtitle>
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      defaultChecked
                      disabled
                      style={{
                        marginTop: 7,
                      }}
                    />
                  </MLSDiv>
                  {isFetching === true ? (
                    <Loader />
                  ) : (
                    <>
                      {localTemplateData && !isEmpty(localTemplateData) ? localTemplateData.map(x => (
                        <>
                          {x.entity_type.includes("other") && x.entity_name === "include_buyer_sale_property_contingency" ? (
                            <>
                              {x.state === state ? (
                                <TitleAndTemplate
                                  index={`old-${x.id}`}
                                  deleteTemplates={deleteTemplates}
                                  setDeleteTemplates={setDeleteTemplates}
                                  templateData={templateData}
                                  localTemplateData={localTemplateData}
                                  setLocalTemplateData={setLocalTemplateData}
                                  addArray={addIncludeBuyerSalePropertyContingency}
                                  setAddArray={setAddIncludeBuyerSalePropertyContingency}
                                  isShifted
                                  state={state}
                                  templateList={templateList}
                                  setTemplateInvalid={setTemplateInvalid}
                                  setTemplateList={setTemplateList}
                                  entityName="include_buyer_sale_property_contingency"
                                  entityType="other"
                                  isEdit={isEdit}
                                  valueName={x.name}
                                  valueTemplate={x.template_id}
                                />
                              ) : null}
                            </>
                          ) : null}
                        </>
                      )) : (
                        <Text>No documents added for this category yet.</Text>
                      )}
                    </>
                  )}
                  {addNewIncludeBuyerSalePropertyContingency === true ? (
                    <>
                      {addIncludeBuyerSalePropertyContingency.length && addIncludeBuyerSalePropertyContingency.map((x, i) => (
                        <>
                          {i > 0 && (
                            <TitleAndTemplate
                              addArray={addIncludeBuyerSalePropertyContingency}
                              setAddArray={setAddIncludeBuyerSalePropertyContingency}
                              index={`include_buyer_sale_property_contingency-${i}`}
                              templateData={templateData}
                              isShifted
                              state={state}
                              templateList={templateList}
                              setTemplateInvalid={setTemplateInvalid}
                              setTemplateList={setTemplateList}
                              entityName="include_buyer_sale_property_contingency"
                              entityType="other"
                              isEdit={isEdit}
                            />
                          )}
                        </>
                      ))}
                    </>
                  ) : null}
                  {isEdit === true ? (
                    <AddNewDoc
                      onClick={() => {
                        setAddNewIncludeBuyerSalePropertyContingency(true)
                        addIncludeBuyerSalePropertyContingencyFunc()
                        templateList.push({
                          idToAdd: `include_buyer_sale_property_contingency-${addIncludeBuyerSalePropertyContingency.length}`,
                          name: "",
                          templateId: "",
                          entityName: "include_buyer_sale_property_contingency",
                          entityType: "other",
                          state,
                        })
                      }}
                    >
                      <Image src={PlusSign} />
                      <AddNew>Add New Document </AddNew>
                    </AddNewDoc>
                  ) : null}
                  <HR last />
                </KeysafeDiv>
                <KeysafeDiv>
                  <MLSDiv contingency>
                    <Subtitle>Would you like to include a Rental Agreement for occupancy prior to closing with this offer? </Subtitle>
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      defaultChecked
                      disabled
                      style={{
                        marginTop: 7,
                      }}
                    />
                  </MLSDiv>
                  {isFetching === true ? (
                    <Loader />
                  ) : (
                    <>
                      {localTemplateData && !isEmpty(localTemplateData) ? localTemplateData.map(x => (
                        <>
                          {x.entity_type.includes("other") && x.entity_name === "include_rental_agreement_for_occupancy_prior_closing" ? (
                            <>
                              {x.state === state ? (
                                <TitleAndTemplate
                                  index={`old-${x.id}`}
                                  deleteTemplates={deleteTemplates}
                                  setDeleteTemplates={setDeleteTemplates}
                                  templateData={templateData}
                                  localTemplateData={localTemplateData}
                                  setLocalTemplateData={setLocalTemplateData}
                                  addArray={addIncludeRentalAgreementForOccupancyPriorClosing}
                                  setAddArray={setAddIncludeRentalAgreementForOccupancyPriorClosing}
                                  isShifted
                                  state={state}
                                  templateList={templateList}
                                  setTemplateInvalid={setTemplateInvalid}
                                  setTemplateList={setTemplateList}
                                  entityName="include_rental_agreement_for_occupancy_prior_closing"
                                  entityType="other"
                                  isEdit={isEdit}
                                  valueName={x.name}
                                  valueTemplate={x.template_id}
                                />
                              ) : null}
                            </>
                          ) : null}
                        </>
                      )) : (
                        <Text>No documents added for this category yet.</Text>
                      )}
                    </>
                  )}
                  {addNewIncludeRentalAgreementForOccupancyPriorClosing === true ? (
                    <>
                      {addIncludeRentalAgreementForOccupancyPriorClosing.length && addIncludeRentalAgreementForOccupancyPriorClosing.map((x, i) => (
                        <>
                          {i > 0 && (
                            <TitleAndTemplate
                              addArray={addIncludeRentalAgreementForOccupancyPriorClosing}
                              setAddArray={setAddOtherDoc}
                              index={`include_rental_agreement_for_occupancy_prior_closing-${i}`}
                              templateData={templateData}
                              isShifted
                              state={state}
                              templateList={templateList}
                              setTemplateInvalid={setTemplateInvalid}
                              setTemplateList={setTemplateList}
                              entityName="include_rental_agreement_for_occupancy_prior_closing"
                              entityType="other"
                              isEdit={isEdit}
                            />
                          )}
                        </>
                      ))}
                    </>
                  ) : null}
                  {isEdit === true ? (
                    <AddNewDoc
                      onClick={() => {
                        setAddNewIncludeRentalAgreementForOccupancyPriorClosing(true)
                        addIncludeRentalAgreementForOccupancyPriorClosingFunc()
                        templateList.push({
                          idToAdd: `include_rental_agreement_for_occupancy_prior_closing-${addIncludeRentalAgreementForOccupancyPriorClosing.length}`,
                          name: "",
                          templateId: "",
                          entityName: "include_rental_agreement_for_occupancy_prior_closing",
                          entityType: "other",
                          state,
                        })
                      }}
                    >
                      <Image src={PlusSign} />
                      <AddNew>Add New Document </AddNew>
                    </AddNewDoc>
                  ) : null}
                  <HR last />
                </KeysafeDiv>
                <KeysafeDiv>
                  <MLSDiv contingency>
                    <Subtitle>Would you like to include a Rental Agreement for occupancy after closing with this offer? </Subtitle>
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      defaultChecked
                      disabled
                      style={{
                        marginTop: 7,
                      }}
                    />
                  </MLSDiv>
                  {isFetching === true ? (
                    <Loader />
                  ) : (
                    <>
                      {localTemplateData && !isEmpty(localTemplateData) ? localTemplateData.map(x => (
                        <>
                          {x.entity_type.includes("other") && x.entity_name === "include_rental_agreement_for_occupancy_after_closing" ? (
                            <>
                              {x.state === state ? (
                                <TitleAndTemplate
                                  index={`old-${x.id}`}
                                  deleteTemplates={deleteTemplates}
                                  setDeleteTemplates={setDeleteTemplates}
                                  templateData={templateData}
                                  localTemplateData={localTemplateData}
                                  setLocalTemplateData={setLocalTemplateData}
                                  addArray={addIncludeRentalAgreementForOccupancyAfterClosing}
                                  setAddArray={setAddIncludeRentalAgreementForOccupancyAfterClosing}
                                  isShifted
                                  state={state}
                                  templateList={templateList}
                                  setTemplateInvalid={setTemplateInvalid}
                                  setTemplateList={setTemplateList}
                                  entityName="include_rental_agreement_for_occupancy_after_closing"
                                  entityType="other"
                                  isEdit={isEdit}
                                  valueName={x.name}
                                  valueTemplate={x.template_id}
                                />
                              ) : null}
                            </>
                          ) : null}
                        </>
                      )) : (
                        <Text>No documents added for this category yet.</Text>
                      )}
                    </>
                  )}
                  {addNewIncludeRentalAgreementForOccupancyAfterClosing === true ? (
                    <>
                      {addIncludeRentalAgreementForOccupancyAfterClosing.length && addIncludeRentalAgreementForOccupancyAfterClosing.map((x, i) => (
                        <>
                          {i > 0 && (
                            <TitleAndTemplate
                              addArray={addIncludeRentalAgreementForOccupancyAfterClosing}
                              setAddArray={setAddIncludeRentalAgreementForOccupancyAfterClosing}
                              index={`include_rental_agreement_for_occupancy_after_closing-${i}`}
                              templateData={templateData}
                              isShifted
                              state={state}
                              templateList={templateList}
                              setTemplateInvalid={setTemplateInvalid}
                              setTemplateList={setTemplateList}
                              entityName="include_rental_agreement_for_occupancy_after_closing"
                              entityType="other"
                              isEdit={isEdit}
                            />
                          )}
                        </>
                      ))}
                    </>
                  ) : null}
                  {isEdit === true ? (
                    <AddNewDoc
                      onClick={() => {
                        setAddNewIncludeRentalAgreementForOccupancyAfterClosing(true)
                        addIncludeRentalAgreementForOccupancyAfterClosingFunc()
                        templateList.push({
                          idToAdd: `include_rental_agreement_for_occupancy_after_closing-${addIncludeRentalAgreementForOccupancyAfterClosing.length}`,
                          name: "",
                          templateId: "",
                          entityName: "include_rental_agreement_for_occupancy_after_closing",
                          entityType: "other",
                          state,
                        })
                      }}
                    >
                      <Image src={PlusSign} />
                      <AddNew>Add New Document </AddNew>
                    </AddNewDoc>
                  ) : null}
                  <HR last />
                </KeysafeDiv>

              </>
            )}
            {/* Washington END */}

            {/* Oregon START */}
            {state === "oregon" && (
              <>
                <OwnershipDiv>
                  <DropdownDiv>
                    <Subtitle>Financing Information</Subtitle>
                    <Select
                      defaultValue="cash"
                      style={{
                        width: 367,
                        marginLeft: 20,
                        marginTop: 0,
                      }}
                      onChange={(value) => {
                        setValueFinancingInformation(value)
                      }}
                      options={[
                        {
                          value: "conventional",
                          label: "Conventional",
                        },
                        {
                          value: "cash",
                          label: "Cash",
                        },
                        {
                          value: "FHA",
                          label: "FHA",
                        },
                        {
                          value: "VA",
                          label: "VA",
                        },
                        {
                          value: "seller_financing",
                          label: "Seller Financing",
                        },
                      ]}
                    />
                  </DropdownDiv>
                  {isFetching === true ? (
                    <Loader />
                  ) : (
                    <>
                      {localTemplateData && !isEmpty(localTemplateData) ? localTemplateData.map(x => (
                        <>
                          {x.entity_type.includes("financing_info") && x.entity_name === valueFinancingInformation ? (
                            <>
                              {x.state === state ? (
                                <TitleAndTemplate
                                  index={`old-${x.id}`}
                                  deleteTemplates={deleteTemplates}
                                  setDeleteTemplates={setDeleteTemplates}
                                  templateData={templateData}
                                  localTemplateData={localTemplateData}
                                  setLocalTemplateData={setLocalTemplateData}
                                  addArray={addFinancingInformation}
                                  setAddArray={setAddFinancingInformation}
                                  isShifted
                                  state={state}
                                  templateList={templateList}
                                  setTemplateInvalid={setTemplateInvalid}
                                  setTemplateList={setTemplateList}
                                  entityName={valueFinancingInformation}
                                  entityType="financing_info"
                                  isEdit={isEdit}
                                  valueName={x.name}
                                  valueTemplate={x.template_id}
                                />
                              ) : null}
                            </>
                          ) : null}
                        </>
                      )) : (
                        <Text>No documents added for this category yet.</Text>
                      )}
                    </>
                  )}
                  {addNewFinancingInformation === true ? (
                    <>
                      {addFinancingInformation.length && addFinancingInformation.map((x, i) => (
                        <>
                          {i > 0 && (
                            <TitleAndTemplate
                              addArray={addFinancingInformation}
                              setAddArray={setAddFinancingInformation}
                              index={`financing_info-${i}`}
                              templateData={templateData}
                              isShifted
                              state={state}
                              templateList={templateList}
                              setTemplateInvalid={setTemplateInvalid}
                              setTemplateList={setTemplateList}
                              entityName={valueFinancingInformation}
                              entityType="financing_info"
                              isEdit={isEdit}
                            />
                          )}
                        </>
                      ))}
                    </>
                  ) : null}
                  {isEdit === true ? (
                    <AddNewDoc
                      onClick={() => {
                        setAddNewFinancingInformation(true)
                        addFinancingInformationFunc()
                        templateList.push({
                          idToAdd: `financing_info-${addFinancingInformation.length}`,
                          name: "",
                          templateId: "",
                          entityName: valueFinancingInformation,
                          entityType: "financing_info",
                          state,
                        })
                      }}
                    >
                      <Image src={PlusSign} />
                      <AddNew>Add New Document </AddNew>
                    </AddNewDoc>
                  ) : null}
                  <HR colist />
                </OwnershipDiv>
                <KeysafeDiv>
                  <MLSDiv contingency>
                    <Subtitle>Would you like to include Private Well Addm to Real Estate Sale Agrmt with this offer?  </Subtitle>
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      defaultChecked
                      disabled
                      style={{
                        marginTop: 7,
                      }}
                    />
                  </MLSDiv>
                  {isFetching === true ? (
                    <Loader />
                  ) : (
                    <>
                      {localTemplateData && !isEmpty(localTemplateData) ? localTemplateData.map(x => (
                        <>
                          {x.entity_type.includes("other") && x.entity_name === "include_private_well_addm_to_real_estate_sale_agrmt" ? (
                            <>
                              {x.state === state ? (
                                <TitleAndTemplate
                                  index={`old-${x.id}`}
                                  deleteTemplates={deleteTemplates}
                                  setDeleteTemplates={setDeleteTemplates}
                                  templateData={templateData}
                                  localTemplateData={localTemplateData}
                                  setLocalTemplateData={setLocalTemplateData}
                                  addArray={addIncludePrivateWellAddmToRealEstateSaleAgrmt}
                                  setAddArray={setAddIncludePrivateWellAddmToRealEstateSaleAgrmt}
                                  isShifted
                                  state={state}
                                  templateList={templateList}
                                  setTemplateInvalid={setTemplateInvalid}
                                  setTemplateList={setTemplateList}
                                  entityName="include_private_well_addm_to_real_estate_sale_agrmt"
                                  entityType="other"
                                  isEdit={isEdit}
                                  valueName={x.name}
                                  valueTemplate={x.template_id}
                                />
                              ) : null}
                            </>
                          ) : null}
                        </>
                      )) : (
                        <Text>No documents added for this category yet.</Text>
                      )}
                    </>
                  )}
                  {addNewIncludePrivateWellAddmToRealEstateSaleAgrmt === true ? (
                    <>
                      {addIncludePrivateWellAddmToRealEstateSaleAgrmt.length && addIncludePrivateWellAddmToRealEstateSaleAgrmt.map((x, i) => (
                        <>
                          {i > 0 && (
                            <TitleAndTemplate
                              addArray={addIncludePrivateWellAddmToRealEstateSaleAgrmt}
                              setAddArray={setAddIncludePrivateWellAddmToRealEstateSaleAgrmt}
                              index={`include_private_well_addm_to_real_estate_sale_agrmt-${i}`}
                              templateData={templateData}
                              isShifted
                              state={state}
                              templateList={templateList}
                              setTemplateInvalid={setTemplateInvalid}
                              setTemplateList={setTemplateList}
                              entityName="include_private_well_addm_to_real_estate_sale_agrmt"
                              entityType="other"
                              isEdit={isEdit}
                            />
                          )}
                        </>
                      ))}
                    </>
                  ) : null}
                  {isEdit === true ? (
                    <AddNewDoc
                      onClick={() => {
                        setAddNewIncludePrivateWellAddmToRealEstateSaleAgrmt(true)
                        addIncludePrivateWellAddmToRealEstateSaleAgrmtFunc()
                        templateList.push({
                          idToAdd: `include_private_well_addm_to_real_estate_sale_agrmt-${addIncludePrivateWellAddmToRealEstateSaleAgrmt.length}`,
                          name: "",
                          templateId: "",
                          entityName: "include_private_well_addm_to_real_estate_sale_agrmt",
                          entityType: "other",
                          state,
                        })
                      }}
                    >
                      <Image src={PlusSign} />
                      <AddNew>Add New Document </AddNew>
                    </AddNewDoc>
                  ) : null}
                  <HR last />
                </KeysafeDiv>
                <KeysafeDiv>
                  <MLSDiv contingency>
                    <Subtitle>Would you like to include Septic Onsite Sewage System Addendum with this offer? </Subtitle>
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      defaultChecked
                      disabled
                      style={{
                        marginTop: 7,
                      }}
                    />
                  </MLSDiv>
                  {isFetching === true ? (
                    <Loader />
                  ) : (
                    <>
                      {localTemplateData && !isEmpty(localTemplateData) ? localTemplateData.map(x => (
                        <>
                          {x.entity_type.includes("other") && x.entity_name === "include_septic_onsite_sewage_system_addendum" ? (
                            <>
                              {x.state === state ? (
                                <TitleAndTemplate
                                  index={`old-${x.id}`}
                                  deleteTemplates={deleteTemplates}
                                  setDeleteTemplates={setDeleteTemplates}
                                  templateData={templateData}
                                  localTemplateData={localTemplateData}
                                  setLocalTemplateData={setLocalTemplateData}
                                  addArray={addIncludeSepticOnsiteSewageSystemAddendum}
                                  setAddArray={setAddIncludeSepticOnsiteSewageSystemAddendum}
                                  isShifted
                                  state={state}
                                  templateList={templateList}
                                  setTemplateInvalid={setTemplateInvalid}
                                  setTemplateList={setTemplateList}
                                  entityName="include_septic_onsite_sewage_system_addendum"
                                  entityType="other"
                                  isEdit={isEdit}
                                  valueName={x.name}
                                  valueTemplate={x.template_id}
                                />
                              ) : null}
                            </>
                          ) : null}
                        </>
                      )) : (
                        <Text>No documents added for this category yet.</Text>
                      )}
                    </>
                  )}
                  {addNewIncludeSepticOnsiteSewageSystemAddendum === true ? (
                    <>
                      {addIncludeSepticOnsiteSewageSystemAddendum.length && addIncludeSepticOnsiteSewageSystemAddendum.map((x, i) => (
                        <>
                          {i > 0 && (
                            <TitleAndTemplate
                              addArray={addIncludeSepticOnsiteSewageSystemAddendum}
                              setAddArray={setAddIncludeSepticOnsiteSewageSystemAddendum}
                              index={`include_septic_onsite_sewage_system_addendum-${i}`}
                              templateData={templateData}
                              isShifted
                              state={state}
                              templateList={templateList}
                              setTemplateInvalid={setTemplateInvalid}
                              setTemplateList={setTemplateList}
                              entityName="include_septic_onsite_sewage_system_addendum"
                              entityType="other"
                              isEdit={isEdit}
                            />
                          )}
                        </>
                      ))}
                    </>
                  ) : null}
                  {isEdit === true ? (
                    <AddNewDoc
                      onClick={() => {
                        setAddNewIncludeSepticOnsiteSewageSystemAddendum(true)
                        addIncludeSepticOnsiteSewageSystemAddendumFunc()
                        templateList.push({
                          idToAdd: `include_septic_onsite_sewage_system_addendum-${addIncludeSepticOnsiteSewageSystemAddendum.length}`,
                          name: "",
                          templateId: "",
                          entityName: "include_septic_onsite_sewage_system_addendum",
                          entityType: "other",
                          state,
                        })
                      }}
                    >
                      <Image src={PlusSign} />
                      <AddNew>Add New Document </AddNew>
                    </AddNewDoc>
                  ) : null}
                  <HR last />
                </KeysafeDiv>
                <KeysafeDiv>
                  <MLSDiv contingency>
                    <Subtitle>Would you like to include a Homeowner's Association/Townhome/Planned Community Addendum with this offer? </Subtitle>
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      defaultChecked
                      disabled
                      style={{
                        marginTop: 7,
                      }}
                    />
                  </MLSDiv>
                  {isFetching === true ? (
                    <Loader />
                  ) : (
                    <>
                      {localTemplateData && !isEmpty(localTemplateData) ? localTemplateData.map(x => (
                        <>
                          {x.entity_type.includes("other") && x.entity_name === "include_homeowners_association_community_addendum" ? (
                            <>
                              {x.state === state ? (
                                <TitleAndTemplate
                                  index={`old-${x.id}`}
                                  deleteTemplates={deleteTemplates}
                                  setDeleteTemplates={setDeleteTemplates}
                                  templateData={templateData}
                                  localTemplateData={localTemplateData}
                                  setLocalTemplateData={setLocalTemplateData}
                                  addArray={addIncludeHomeownersAssociationCommunityAddendum}
                                  setAddArray={setAddIncludeHomeownersAssociationCommunityAddendum}
                                  isShifted
                                  state={state}
                                  templateList={templateList}
                                  setTemplateInvalid={setTemplateInvalid}
                                  setTemplateList={setTemplateList}
                                  entityName="include_homeowners_association_community_addendum"
                                  entityType="other"
                                  isEdit={isEdit}
                                  valueName={x.name}
                                  valueTemplate={x.template_id}
                                />
                              ) : null}
                            </>
                          ) : null}
                        </>
                      )) : (
                        <Text>No documents added for this category yet.</Text>
                      )}
                    </>
                  )}
                  {addNewIncludeHomeownersAssociationCommunityAddendum === true ? (
                    <>
                      {addIncludeHomeownersAssociationCommunityAddendum.length && addIncludeHomeownersAssociationCommunityAddendum.map((x, i) => (
                        <>
                          {i > 0 && (
                            <TitleAndTemplate
                              addArray={addIncludeHomeownersAssociationCommunityAddendum}
                              setAddArray={setAddIncludeHomeownersAssociationCommunityAddendum}
                              index={`include_homeowners_association_community_addendum-${i}`}
                              templateData={templateData}
                              isShifted
                              state={state}
                              templateList={templateList}
                              setTemplateInvalid={setTemplateInvalid}
                              setTemplateList={setTemplateList}
                              entityName="include_homeowners_association_community_addendum"
                              entityType="other"
                              isEdit={isEdit}
                            />
                          )}
                        </>
                      ))}
                    </>
                  ) : null}
                  {isEdit === true ? (
                    <AddNewDoc
                      onClick={() => {
                        setAddNewIncludeHomeownersAssociationCommunityAddendum(true)
                        addIncludeHomeownersAssociationCommunityAddendumFunc()
                        templateList.push({
                          idToAdd: `include_homeowners_association_community_addendum-${addIncludeHomeownersAssociationCommunityAddendum.length}`,
                          name: "",
                          templateId: "",
                          entityName: "include_homeowners_association_community_addendum",
                          entityType: "other",
                          state,
                        })
                      }}
                    >
                      <Image src={PlusSign} />
                      <AddNew>Add New Document </AddNew>
                    </AddNewDoc>
                  ) : null}
                  <HR last />
                </KeysafeDiv>
                <KeysafeDiv>
                  <MLSDiv contingency>
                    <Subtitle>Would you like to include a Investment Property Addendum with this offer?  </Subtitle>
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      defaultChecked
                      disabled
                      style={{
                        marginTop: 7,
                      }}
                    />
                  </MLSDiv>
                  {isFetching === true ? (
                    <Loader />
                  ) : (
                    <>
                      {localTemplateData && !isEmpty(localTemplateData) ? localTemplateData.map(x => (
                        <>
                          {x.entity_type.includes("other") && x.entity_name === "include_investment_property_addendum" ? (
                            <>
                              {x.state === state ? (
                                <TitleAndTemplate
                                  index={`old-${x.id}`}
                                  deleteTemplates={deleteTemplates}
                                  setDeleteTemplates={setDeleteTemplates}
                                  templateData={templateData}
                                  localTemplateData={localTemplateData}
                                  setLocalTemplateData={setLocalTemplateData}
                                  addArray={addIncludeInvestmentPropertyAddendum}
                                  setAddArray={setAddIncludeInvestmentPropertyAddendum}
                                  isShifted
                                  state={state}
                                  templateList={templateList}
                                  setTemplateInvalid={setTemplateInvalid}
                                  setTemplateList={setTemplateList}
                                  entityName="include_investment_property_addendum"
                                  entityType="other"
                                  isEdit={isEdit}
                                  valueName={x.name}
                                  valueTemplate={x.template_id}
                                />
                              ) : null}
                            </>
                          ) : null}
                        </>
                      )) : (
                        <Text>No documents added for this category yet.</Text>
                      )}
                    </>
                  )}
                  {addNewIncludeInvestmentPropertyAddendum === true ? (
                    <>
                      {addIncludeInvestmentPropertyAddendum.length && addIncludeInvestmentPropertyAddendum.map((x, i) => (
                        <>
                          {i > 0 && (
                            <TitleAndTemplate
                              addArray={addIncludeInvestmentPropertyAddendum}
                              setAddArray={setAddIncludeInvestmentPropertyAddendum}
                              index={`include_investment_property_addendum-${i}`}
                              templateData={templateData}
                              isShifted
                              state={state}
                              templateList={templateList}
                              setTemplateInvalid={setTemplateInvalid}
                              setTemplateList={setTemplateList}
                              entityName="include_investment_property_addendum"
                              entityType="other"
                              isEdit={isEdit}
                            />
                          )}
                        </>
                      ))}
                    </>
                  ) : null}
                  {isEdit === true ? (
                    <AddNewDoc
                      onClick={() => {
                        setAddNewIncludeInvestmentPropertyAddendum(true)
                        addIncludeInvestmentPropertyAddendumFunc()
                        templateList.push({
                          idToAdd: `include_investment_property_addendum-${addIncludeInvestmentPropertyAddendum.length}`,
                          name: "",
                          templateId: "",
                          entityName: "include_investment_property_addendum",
                          entityType: "other",
                          state,
                        })
                      }}
                    >
                      <Image src={PlusSign} />
                      <AddNew>Add New Document </AddNew>
                    </AddNewDoc>
                  ) : null}
                  <HR last />
                </KeysafeDiv>
                <KeysafeDiv>
                  <MLSDiv contingency>
                    <Subtitle>Would you like to include a Agreement for occupancy prior to closing with this offer? </Subtitle>
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      defaultChecked
                      disabled
                      style={{
                        marginTop: 7,
                      }}
                    />
                  </MLSDiv>
                  {isFetching === true ? (
                    <Loader />
                  ) : (
                    <>
                      {localTemplateData && !isEmpty(localTemplateData) ? localTemplateData.map(x => (
                        <>
                          {x.entity_type.includes("other") && x.entity_name === "include_agreement_occupancy_prior_to_closing" ? (
                            <>
                              {x.state === state ? (
                                <TitleAndTemplate
                                  index={`old-${x.id}`}
                                  deleteTemplates={deleteTemplates}
                                  setDeleteTemplates={setDeleteTemplates}
                                  templateData={templateData}
                                  localTemplateData={localTemplateData}
                                  setLocalTemplateData={setLocalTemplateData}
                                  addArray={addIncludeAgreementOccupancyPriorToClosing}
                                  setAddArray={setAddIncludeAgreementOccupancyPriorToClosing}
                                  isShifted
                                  state={state}
                                  templateList={templateList}
                                  setTemplateInvalid={setTemplateInvalid}
                                  setTemplateList={setTemplateList}
                                  entityName="include_agreement_occupancy_prior_to_closing"
                                  entityType="other"
                                  isEdit={isEdit}
                                  valueName={x.name}
                                  valueTemplate={x.template_id}
                                />
                              ) : null}
                            </>
                          ) : null}
                        </>
                      )) : (
                        <Text>No documents added for this category yet.</Text>
                      )}
                    </>
                  )}
                  {addNewIncludeAgreementOccupancyPriorToClosing === true ? (
                    <>
                      {addIncludeAgreementOccupancyPriorToClosing.length && addIncludeAgreementOccupancyPriorToClosing.map((x, i) => (
                        <>
                          {i > 0 && (
                            <TitleAndTemplate
                              addArray={addIncludeAgreementOccupancyPriorToClosing}
                              setAddArray={setAddIncludeAgreementOccupancyPriorToClosing}
                              index={`include_agreement_occupancy_prior_to_closing-${i}`}
                              templateData={templateData}
                              isShifted
                              state={state}
                              templateList={templateList}
                              setTemplateInvalid={setTemplateInvalid}
                              setTemplateList={setTemplateList}
                              entityName="include_agreement_occupancy_prior_to_closing"
                              entityType="other"
                              isEdit={isEdit}
                            />
                          )}
                        </>
                      ))}
                    </>
                  ) : null}
                  {isEdit === true ? (
                    <AddNewDoc
                      onClick={() => {
                        setAddNewIncludeAgreementOccupancyPriorToClosing(true)
                        addIncludeAgreementOccupancyPriorToClosingFunc()
                        templateList.push({
                          idToAdd: `include_agreement_occupancy_prior_to_closing-${addIncludeAgreementOccupancyPriorToClosing.length}`,
                          name: "",
                          templateId: "",
                          entityName: "include_agreement_occupancy_prior_to_closing",
                          entityType: "other",
                          state,
                        })
                      }}
                    >
                      <Image src={PlusSign} />
                      <AddNew>Add New Document </AddNew>
                    </AddNewDoc>
                  ) : null}
                  <HR last />
                </KeysafeDiv>
                <KeysafeDiv>
                  <MLSDiv contingency>
                    <Subtitle>Would you like to include a Agreement for occupancy after closing with this offer? </Subtitle>
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      defaultChecked
                      disabled
                      style={{
                        marginTop: 7,
                      }}
                    />
                  </MLSDiv>
                  {isFetching === true ? (
                    <Loader />
                  ) : (
                    <>
                      {localTemplateData && !isEmpty(localTemplateData) ? localTemplateData.map(x => (
                        <>
                          {x.entity_type.includes("other") && x.entity_name === "include_agreement_occupancy_after_to_closing" ? (
                            <>
                              {x.state === state ? (
                                <TitleAndTemplate
                                  index={`old-${x.id}`}
                                  deleteTemplates={deleteTemplates}
                                  setDeleteTemplates={setDeleteTemplates}
                                  templateData={templateData}
                                  localTemplateData={localTemplateData}
                                  setLocalTemplateData={setLocalTemplateData}
                                  addArray={addIncludeAgreementOccupancyAfterToClosing}
                                  setAddArray={setAddIncludeAgreementOccupancyAfterToClosing}
                                  isShifted
                                  state={state}
                                  templateList={templateList}
                                  setTemplateInvalid={setTemplateInvalid}
                                  setTemplateList={setTemplateList}
                                  entityName="include_agreement_occupancy_after_to_closing"
                                  entityType="other"
                                  isEdit={isEdit}
                                  valueName={x.name}
                                  valueTemplate={x.template_id}
                                />
                              ) : null}
                            </>
                          ) : null}
                        </>
                      )) : (
                        <Text>No documents added for this category yet.</Text>
                      )}
                    </>
                  )}
                  {addNewIncludeAgreementOccupancyAfterToClosing === true ? (
                    <>
                      {addIncludeAgreementOccupancyAfterToClosing.length && addIncludeAgreementOccupancyAfterToClosing.map((x, i) => (
                        <>
                          {i > 0 && (
                            <TitleAndTemplate
                              addArray={addIncludeAgreementOccupancyAfterToClosing}
                              setAddArray={setAddIncludeAgreementOccupancyAfterToClosing}
                              index={`include_agreement_occupancy_after_to_closing-${i}`}
                              templateData={templateData}
                              isShifted
                              state={state}
                              templateList={templateList}
                              setTemplateInvalid={setTemplateInvalid}
                              setTemplateList={setTemplateList}
                              entityName="include_agreement_occupancy_after_to_closing"
                              entityType="other"
                              isEdit={isEdit}
                            />
                          )}
                        </>
                      ))}
                    </>
                  ) : null}
                  {isEdit === true ? (
                    <AddNewDoc
                      onClick={() => {
                        setAddNewIncludeAgreementOccupancyAfterToClosing(true)
                        addIncludeAgreementOccupancyAfterToClosingFunc()
                        templateList.push({
                          idToAdd: `include_agreement_occupancy_after_to_closing-${addIncludeAgreementOccupancyAfterToClosing.length}`,
                          name: "",
                          templateId: "",
                          entityName: "include_agreement_occupancy_after_to_closing",
                          entityType: "other",
                          state,
                        })
                      }}
                    >
                      <Image src={PlusSign} />
                      <AddNew>Add New Document </AddNew>
                    </AddNewDoc>
                  ) : null}
                  <HR last />
                </KeysafeDiv>
                <KeysafeDiv>
                  <MLSDiv contingency>
                    <Subtitle>Would you like to include a Advisory to Buyer Waiving Contingencies with this offer? </Subtitle>
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      defaultChecked
                      disabled
                      style={{
                        marginTop: 7,
                      }}
                    />
                  </MLSDiv>
                  {isFetching === true ? (
                    <Loader />
                  ) : (
                    <>
                      {localTemplateData && !isEmpty(localTemplateData) ? localTemplateData.map(x => (
                        <>
                          {x.entity_type.includes("other") && x.entity_name === "include_advisory_to_buyer_waiving_contingencies" ? (
                            <>
                              {x.state === state ? (
                                <TitleAndTemplate
                                  index={`old-${x.id}`}
                                  deleteTemplates={deleteTemplates}
                                  setDeleteTemplates={setDeleteTemplates}
                                  templateData={templateData}
                                  localTemplateData={localTemplateData}
                                  setLocalTemplateData={setLocalTemplateData}
                                  addArray={addIncludeAdvisoryToBuyerWaivingContingencies}
                                  setAddArray={setAddIncludeAdvisoryToBuyerWaivingContingencies}
                                  isShifted
                                  state={state}
                                  templateList={templateList}
                                  setTemplateInvalid={setTemplateInvalid}
                                  setTemplateList={setTemplateList}
                                  entityName="include_advisory_to_buyer_waiving_contingencies"
                                  entityType="other"
                                  isEdit={isEdit}
                                  valueName={x.name}
                                  valueTemplate={x.template_id}
                                />
                              ) : null}
                            </>
                          ) : null}
                        </>
                      )) : (
                        <Text>No documents added for this category yet.</Text>
                      )}
                    </>
                  )}
                  {addNewIncludeAdvisoryToBuyerWaivingContingencies === true ? (
                    <>
                      {addIncludeAdvisoryToBuyerWaivingContingencies.length && addIncludeAdvisoryToBuyerWaivingContingencies.map((x, i) => (
                        <>
                          {i > 0 && (
                            <TitleAndTemplate
                              addArray={addIncludeAdvisoryToBuyerWaivingContingencies}
                              setAddArray={setAddIncludeAdvisoryToBuyerWaivingContingencies}
                              index={`include_advisory_to_buyer_waiving_contingencies-${i}`}
                              templateData={templateData}
                              isShifted
                              state={state}
                              templateList={templateList}
                              setTemplateInvalid={setTemplateInvalid}
                              setTemplateList={setTemplateList}
                              entityName="include_advisory_to_buyer_waiving_contingencies"
                              entityType="other"
                              isEdit={isEdit}
                            />
                          )}
                        </>
                      ))}
                    </>
                  ) : null}
                  {isEdit === true ? (
                    <AddNewDoc
                      onClick={() => {
                        setAddNewIncludeAdvisoryToBuyerWaivingContingencies(true)
                        addIncludeAdvisoryToBuyerWaivingContingenciesFunc()
                        templateList.push({
                          idToAdd: `include_advisory_to_buyer_waiving_contingencies-${addIncludeAdvisoryToBuyerWaivingContingencies.length}`,
                          name: "",
                          templateId: "",
                          entityName: "include_advisory_to_buyer_waiving_contingencies",
                          entityType: "other",
                          state,
                        })
                      }}
                    >
                      <Image src={PlusSign} />
                      <AddNew>Add New Document </AddNew>
                    </AddNewDoc>
                  ) : null}
                  <HR last />
                </KeysafeDiv>

              </>
            )}
            {/* Oregon END */}
          </Body>
        </Content>
      )}
    </Wrap>
  )
}

export default container(ViewPage)
